.alert-danger.alerDangrPadd {
  padding: 7px 10px;
}

.currency-wrap {
  position: relative;
}

.currency-code {
  position: absolute;
  left: 8px;
  top: 10px;
}

p.seen_all_txt {
  margin-left: 20px;
}

.app__launcher {
  border: none !important;
  z-index: 9990 !important;
  /*width: 60px !important;height: 60px !important;*/
}

.padding0 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.btn-success.btnSucCut {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  margin-left: 10px;
  padding: 7px 15px;
}

.stepFormMidSec::-webkit-scrollbar {
  width: 8px;
}

.stepFormMidSec::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px #999999;
  border-radius: 10px;
}

.stepFormMidSec::-webkit-scrollbar-thumb {
  background: #004964;
  border-radius: 10px;
}

.stepFormMidSec::-webkit-scrollbar-thumb:hover {
  background: #004964;
}

.midBoxBut .customBluebut {
  display: block;
  width: 100%;
  max-width: 250px;
  margin-left: 0 !important;
}

.containerImg [type=file]+label {
  width: 100%;
  max-width: 250px;
  text-align: center;
}

.stepFormContainer {
  position: relative;
}

.inputCustDiv {
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  height: calc(2.25rem + 2px);
}

.fakeimg {
  height: 200px;
  background: #aaa;
}

.containerImg [type=file] {
  height: 0;
  overflow: hidden;
  width: 0;
}

.stepFormBgOverlay {
  position: fixed;
  top: 0px;
  bottom: 0;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.9);
  height: 100%;
  z-index: 999;
}

.containerImg [type=file]+label {
  background: #004964;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: inherit;
  font-weight: 500;
  margin-bottom: 1rem;
  outline: none;
  padding: 10px 20px;
  line-height: 20px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
}

#display_image {
  width: 300px;
  height: 169px;
  border: 1px solid #dfd9d9;
  background-position: center;
  background-size: cover;
}

.midBoxBut input[type=radio] {
  margin-right: 10px;
}


.posRel {
  z-index: 9999;
  position: fixed;
  left: 50%;
  width: 100%;
  max-width: 600px;
  height: 100%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0;
  padding: 0;
}

.stepFormMidSec {
  position: fixed;
  border-radius: 10px;
  box-shadow: 0 2px 10px 1px #484343;
  margin-top: 0px;
  background: #fff;
  border: none;
  z-index: 0;
  overflow-y: auto;
  height: 100%;
  width: 100%;
}

.stepTopSection {
  background-color: #4B93AD;
  border-radius: 10px 10px 0 0;
  color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  text-align: center;
  padding: 10px 20px 10px;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 9999;
}

.stepTopSection-buyer {
  background-color: #4B93AD;
  border-radius: 10px 10px 0 0;
  color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  text-align: center;
  padding: 15px 20px 25px;
  align-items: center;
  justify-content: center;
}

.midBoxBut {
  padding: 30px;
  margin-top: 90px;
}

.alert-danger.alerDangrPadd {
  padding: 7px 10px;
  position: absolute;
  bottom: 50px;
}

.customBluebut {
  color: white !important;
  border-radius: 5px;
  background-color: #004964 !important;
  box-shadow: none;
  border: none;
  padding: 8px 25px;
  margin-right: 20px;
  margin-bottom: 15px;
  font-weight: 500;
}

.fixFooter {
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: flex;
  background-color: #f4f7f9;
  height: 70px;
  border: 1px solid #d4d4d4;
  padding: 0;
  margin: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  -webkit-justify-content: center;
  justify-content: center;
  border-radius: 0 0 10px 10px;
}

.stepwizard-step p {
  margin-top: 10px;
}

.stepwizard-row {
  display: table-row;
}

.stepwizard {
  display: table;
  width: 100%;
  position: relative;
}

.stepwizard-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.stepwizard-row:before {
  top: 14px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 100%;
  height: 1px;
  background-color: #ccc;
  z-order: 0;
}

.stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}

.delete-content button {
  background: #4B93AD !important;
  border-color: #4B93AD !important;
  padding: 7px 20px;
  display: inline-block;
  cursor: pointer;
  color: #fff;
  border: none;
  font-size: 1rem;
  border-radius: .25rem;
}

.delete-content button:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

.delete-content button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}

.nxtBtn.btn.btn-primary {
  background: #4B93AD !important;
  border-color: #4B93AD !important;
  padding: 7px 20px;
  display: inline-block;
}

.bKBtn.btn.btn-primary {
  background: #4B93AD !important;
  border-color: #4B93AD !important;
  padding: 7px 20px;
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
}

.leaseBtn.btn.btn-primary {
  background: #4B93AD !important;
  border-color: #4B93AD !important;
  padding: 7px 20px;
  display: inline-block;
  margin-right: 10px;
}

.photo img,
.upload_photo img {
  width: 100%;
}

.submit-container,
.delete-content {
  margin: 10px 0;
}

.submit-container button {
  max-width: 250px;
  width: 100%;
}

.btn.submitBtn {
  margin-right: 10px;
}

.midBoxBut p {
  font-size: 20px;
  margin-bottom: 42px;
}

button.closeBtn {
  position: absolute;
  top: 5px;
  right: 20px;
  background: transparent;
  border: none;
  font-size: 35px;
  color: aliceblue;
  border-radius: 100px;
  height: 30px;
  width: 30px;
  line-height: 27px !important;
  cursor: pointer;
  z-index: 9;
}

.search-pg-wrapper {
  position: relative;
}

.stepFormContainer {
  /*display: flex;align-items: center;justify-content: center;*/
}

.stepTopSection h5 {
  font-size: 24px;
}

.containerImg {
  width: 100%;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.logoImg {
  width: 150px;
  height: 80px;
}

.customBluebut.active {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 50%) !important;
}

.video-container {
  margin-top: 40px !important;
}

h3.heading,
.photo-container h3,
.video-container h3 {
  font-size: 20px;
}

th.tbl_action {
  width: 160px;
}

.modal-footer {
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media only screen and (min-width: 767px) {
  .shipname-and-heart-div {
    padding-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .stepFormContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-top: 0;
    padding-top: 0;
    bottom: 0;
  }

  .fixFooter {
    box-shadow: none;
    border-radius: 0;
    height: 60px;
    margin: 0;
  }

  .stepTopSection {
    border-radius: 0;
  }

  .stepFormContainer .stepFormMidSec {
    border-radius: 0;
    box-shadow: none;
  }

  .midBoxBut p {
    font-size: 18px;
    margin-bottom: 25px;
  }

  .posRel {
    z-index: 9999;
    position: fixed;
    left: 0%;
    top: 0%;
    -webkit-transform: translate(-0%, -0%);
    transform: none;
    width: 100%;
    max-width: 100%;
  }

  .alert-danger.alerDangrPadd {
    padding: 7px 10px;
  }

  .container-fluid.broker-offer-section {
    margin: 0;
  }

  .photo-container {
    margin-top: 30px;
  }

  .midBoxBut .containerImg img {
    width: 100%;
  }

  form.listing-form .input-group button {
    width: 45%;
  }

  .width-100 {
    width: 100% !important;
    max-width: 100% !important;
    flex: auto !important;
  }

  .margin-bottom-100 {
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 480px) {
  .row.no-gutters img {
    height: 300px;
    width: 100%;
  }
}

@media (max-width: 520px) {
  th.hide {
    display: none;
  }

  table {
    border: 0;
  }

  table.custom-responsive {
    border: 0;
  }

  .custom-responsive thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .custom-responsive tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }

  .custom-responsive td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  .custom-responsive td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }

}

.tooltipp {
  position: relative;
}

.tooltipp .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -30px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.tooltipp:hover .tooltiptext {
  visibility: visible;
}



.modal-login-btns button {
  margin-top: 10px !important;
}

.error {
  border: 2px solid red;
}

.err-msg {
  color: red !important;
  width: 100%;
  display: block;
}

.required-field::after {
  content: "*";
  color: red;
}

.soldprice {
  top: -5px !important
}

.crossbtn {
  color: blue !important;
  width: 100%;
}

#cometchat__widget {
  position: relative !important;
  z-index: 9999999 !important;
}