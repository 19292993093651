.header .navbar-nav .nav-item.active .nav-link,
.header .navbar-nav .nav-item .nav-link:hover,
header.pb .header .navbar-nav .nav-item .nav-link:hover,
.view-btn,
.face.front,
.bottom-list ul li a:hover,
.card .card-body h3:hover,
.card:hover .card-body h3,
.post-nfo li:hover,
.post-nfo li:hover a,
.blog-single-post:hover .post_info > h3 a,
.cm-reply,
.widget-search form button,
.widget-catgs ul li a,
.ps-info > h3 a:hover,
.btn1,
.blog-grid-posts .post_info > a:hover,
.cont-links li a,
.cont-links li span,
.socio-links li a:hover,
.cont_info li i,
.cont_info li a,
.social_links li a:hover,
.view-change ul.nav-tabs .nav-link.active,
.card_bod_full .card-footer .btn-default,
.btn-default,
.sidebar.layout2 .ps-info > strong,
.more-feat i,
.contct-nf > span,
.apartment-sec .rate-info h5,
.comment-info:before,
.price > h2,
.agent_info > strong,
.agent:hover .agent_info h3 a,
.list-products .card:hover .card-body h3,
.social-links li a:hover,
.slick-prev:focus,
.slick-next:focus,
.slick-prev:before,
.slick-next:before,
.banner_text .card .card-footer > a,
.catg-icon > i,
.map-city-links ul li a:hover,
a.link,
.selected,
.dropdown-item.active, 
.dropdown-item:active,
.post-share > a:hover,
.h-card-body .title,
.h-page-card .title a:hover,
.blog-single-post:hover .post_info > a {
	color: #6a7be7;
}


.banner-search .btn,
.explore-feature .card .card-body i,
.cluster-visible,
.face.back,
.widget-tags > ul li a,
.post_info > a:hover,
.pagination .page-item.active .page-link,
.pagination .page-item .page-link:hover,
.contact_form > form button:hover,
.post-comment-sec form button:hover,
.input-field input[type="radio"]:checked + label span,
.input-field input[type="checkbox"]:checked + label span,
.features-dv .input-field input[type="checkbox"] + label span,
.btn2,
.price:hover .btn-default,
.discover-text .btn-default,
.slick-prev:hover,
.slick-next:hover,
.banner_text .rate-info,
.btn-default.st1,
.categories-info:hover,
.card .card-footer a .la-heart-o:hover,
.footer-social a:hover,
.signin-btn b:before,
.success-message, 
.error-message,
.error,
.btn.btn-local-primary,
.block-header,
.model-item .title:hover,
.language-selector .drop-menu .dropeddown li:hover {
	background-color: #6a7be7;
}


.header .navbar-nav .submit-btn a,
.view-btn,
.cluster-visible:before,
.face.front,
.face.back,
.blog-single-post > blockquote,
.btn1,
.btn-default,
.btn.btn-local-primary,
.language-selector .drop-menu .dropeddown li:hover {
	border-color: #6a7be7;
}