.userdetails {
  padding-top: 30px;
}

.sub_cont h2 {
  padding-bottom: 50px;
}

.sub_cont {
  border-radius: 20px;
  padding: 20px;
}

.userdetails-form .form-control {
  line-height: 34px;
}

.userdetails-form label {
  font-weight: 600;
}

.userdetails-form select.form-control {
  height: 46px !important;
}

.userdetails-form input::-webkit-input-placeholder {
  /* Edge */
  font-weight: 500;
}

.userdetails-form input:-ms-input-placeholder {
  /* Internet Explorer */
  font-weight: 500;
}


.userdetails-form input::placeholder {
  font-weight: 500;
}

.main_container {
  margin-top: 92px;
  margin-bottom: 100px;

}

.button_div {
  padding: 15px;
}

.button_div button {
  width: 11em;
  height: 3em;
  color: whitesmoke;
  background: #004964;
  border-radius: 5px;
  border: 1px solid #004964;
  outline: none;
}

.button_div button:hover {
  background: #4b93ad;
  border: 1px solid #4b93ad;
}

.button_div button:hover,
.button_div button:focus,
.button_div button:active {
  border: 1px solid #4b93ad;
}

@media (max-width: 991px) {
  .main_container {

    margin-bottom: 50px;

  }
}

@media (max-width: 767px) {
  .main_container {
    margin-top: 50px;
    margin-bottom: 30px;

  }
}

.chat-box {
  height: 500px;
  overflow-y: scroll;
  border: 2px solid black;
  padding: 10px;
  width: 100%;
}

.bot-chat {
  padding: 10px;
  float: left;
  background-color: #004964;
  color: white;
  border: 1px solid grey;
  border-radius: 10px;
  min-width: 55%;
  min-height: 50px;
  font-size: 16px;
}

.user-chat {
  padding: 10px;
  float: right;
  background-color: #e2e5e852;
  color: black;
  border: 1px solid grey;
  border-radius: 10px;
  min-width: 55%;
  min-height: 50px;
  font-size: 16px;
}

.bot-chat-suggestion {
  padding: 5px;
  float: left;
  background-color: rgb(17, 88, 90);
  color: white;
  border: 1px solid grey;
  border-radius: 10px;
  min-width: 41%;
  margin-left: 5%;
}


/********* New Changes on Chat Profile ********/
.chat-box {
  height: 500px;
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.chat-text::-webkit-scrollbar {
  width: 5px;
}

.chat-text::-webkit-scrollbar-track {
  border-radius: 5px;
}

.chat-text::-webkit-scrollbar-thumb {
  background: #034A63 !important;
  border-radius: 10px;
}

.chat-text {
  height: 420px;
  overflow-y: scroll;
  border-bottom: 1px solid;
}

.chat-box .chat-input {
  width: 82%;
  height: 50px;
  border-radius: 10px 0px 0px 10px;
  border: 1px solid #004964;
  padding: 10px;
}

.chat-box .chat-btn {

  width: 15%;
  color: #fff;
  background: #004964;
  font-size: 18px;
  font-weight: 400;
  border: 0px solid;
  height: 51px;
  border-radius: 0 10px 10px 0;
}

.chat-input-div {

  width: 100%;
  bottom: 10px;
  display: flex;
  margin-top: 8px;
}

.bot-map-chat {
  width: 100%;
  float: left;
}

@media (max-width: 460px) {
  .chat-input-div {
    width: 98%;
  }
}