body {
  width: 100%;
  margin: 0;
}

* {
  box-sizing: border-box;

}

@font-face {
  font-family: "Gotham-Pro-Bold";
  src: url("../fonts/Gotham-Pro/Gotham-Pro-Bold.ttf");
}

@font-face {
  font-family: "Gotham-Pro-Medium";
  src: url("../fonts/Gotham-Pro/Gotham-Pro-Medium.ttf");
}

@font-face {
  font-family: "Gotham-Pro-Regular";
  src: url("../fonts/Gotham-Pro/Gotham-Pro-Regular.ttf");
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: grey;
}

body::-webkit-scrollbar {
  width: 5px;
  background-color: grey;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: grey;
}

/*--------------------------------------------------Home page start-------------------------------------------------------------*/
#video-container {
  /*position: absolute;*/
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: calc(100vh + 378px);
  /*z-index: 10;*/
  overflow: hidden;
}

/*.touch #video-container {
  background: url('../img/sea-image-min.e6adfa71.png') no-repeat center;
  background-size: cover;
}*/

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*.touch video {
  display: none;
}*/

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  /*background-color: rgba(0, 0, 0, 0.7);*/
  /*padding-left: 6%;*/
  /*padding-right: 6%;*/
  display: table;
}

.overlay__inner {
  width: 100%;
  height: auto;
  /*display: table-cell;*/
  vertical-align: middle;
  text-align: center;
}

.overlay h2 {
  max-width: 40em;
  font-size: 28px;
  font-weight: 700;
  color: #fff;
  font-family: "Raleway", sans-serif;
  line-height: 1.3;
  margin: 0 auto;
}

/*.overlay p {
  padding-top: 1em;
  max-width: 40em;
  margin: 0 auto;
}*/
.overlay a.btn {
  display: inline-block;
  margin-top: 25px;
  border: 1px solid #00c1bb;
  color: #00c1bb;
  text-decoration: none;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  overflow: hidden;
}

.overlay a.btn span {
  display: inline-block;
  padding: 6px 40px;
  transition: transform 0.4s;
}

.overlay a.btn .row2 {
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(100%);
}

.overlay a.btn:hover .row1 {
  transform: translateY(-100%);
}

.overlay a.btn:hover .row2 {
  transform: translateY(0);
}

#hero-area {
  /*background-image: url('../img/sea-image-min.e6adfa71.png');*/
  /*background-image: linear-gradient(
    to right,
    #f00, #00f
  );*/
  background-size: contain;
  width: 100%;
  /*height: 130vh;*/
  object-fit: cover;
  height: 100vh;
  text-align: center;
}

#navbar {
  background-color: #488ca6b5;
  color: #fff;
  padding: 10px;
  /* Without the four declarations below (position, width, box-sizing, top), then hero area and navbar are at the top.  With them, there is a white bar on CodePen. On my site that top white bar is not there. */
  position: fixed;
  height: 62px;
  width: 100%;
  box-sizing: border-box;
  z-index: 10;
}

#navbar-flexbox {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

#phone_number {
  font-family: "Gotham-Pro-Medium";
  font-size: 16px;
  font-weight: 500;
  line-height: 39px;
  color: #ffffff;
  text-align: center;
}

/* 
#nav-hamburger {
  position: absolute;
  top: 0;
  right: 0;
  
  margin-top: 9px;
  margin-bottom: 9px;
  margin-right: 8%;
} */

.banner {
  text-align: center;
  /* padding-bottom: 100px; */
  display: flex;
  flex-direction: column;
  place-content: center;
  height: 100vh;
}

/* #hero-name {
  padding-top: 200px;
} */

#hero-blurb {
  font-family: "Gotham-Pro-Bold";
  font-weight: 500;
  font-size: 40px;
  color: #ffffff;
}

.banner-heading-underline {
  background: white;
  height: 6px;
  width: 100%;
  max-width: 750px;
  margin-bottom: 20px;
  margin-inline: auto;
  opacity: 1;
}

.logo-img {
  width: 314px;
}

.explore_items_for_sale_btn {
  font-family: "Gotham-Pro-Regular";
  background-color: #024861;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  padding: 8px 15px 8px 25px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  margin-right: 8px;
  cursor: pointer;
}

.explore_items_wanted_btn {
  font-family: "Gotham-Pro-Regular";
  background-color: #4b91a9;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  padding: 8px 15px 8px 25px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  cursor: pointer;
}

.commission_box_section {
  /*padding-bottom: 70px;*/
  padding-top: 50px;
}

.commission_free_box {
  background-color: #02486182;
  border: 1px solid #ffffff;
  /* opacity: 0.6;*/
  border-radius: 10px;
  padding: 40px 20px;
  height: 100%;
  display: grid;
}

.fsbo_text {
  font-family: "Gotham-Pro-Bold";
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}

.click_here_to_sell_now_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  margin: 15px auto;
}

.brand_new_service_btn {
  background-color: #f7721f;
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  padding: 8px 15px 8px 25px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  cursor: pointer;
}

.traditional_service_btn {
  background-color: #28a2cf;
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  padding: 8px 15px 8px 25px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  cursor: pointer;
}

.view_all_service_btn {
  background-color: #4c93ab;
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  padding: 8px 15px 8px 25px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  cursor: pointer;
}

h1 {
  margin-top: 0;
  line-height: 6;
}

/*#main-body {
  padding: 5px 25px;
}*/

#footer {
  background-color: #0f0;
}

/*#hero-arrow {
  line-height: 5;
}*/

.dropdown-wrapper {
  position: relative;
  /*  width: 150px;*/
  /*margin: 10px;*/
  padding: 7px 10px;
  background: #034a63;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease-out;
  float: left;
  margin-right: 10px;
}

.dropdown-wrapper span {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  color: #ffffff;
}

.world_icon {
  height: 20px;
  margin-top: -4px;
}

/*#dropdown-wrapper:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -3px;
  border-width: 6px 6px 0 6px;
  border-style: solid;
  border-color: #ffffff transparent;
}*/
.dropdown-wrapper.is-active {
  border-radius: 5px 5px 0 0;
  background: #f05b55;
  box-shadow: none;
  border-bottom: none;
  color: white;
}

.dropdown-wrapper.is-active:after {
  border-color: #ffffff transparent;
  transform: rotate(180deg);
}

.dropdown-wrapper.is-active .dropdown-list {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  max-height: 400px;
}

.dropdown-list {
  /* Size & position */
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  /* Styles */
  background: #fff;
  border-radius: 0 0 5px 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-top: none;
  border-bottom: none;
  list-style: none;
  transition: all 0.3s ease-out;
  /* Hiding */
  max-height: 0;
  overflow: hidden;
  padding-left: 0;
}

.dropdown-list li {
  font-family: "Gotham-Pro-Regular";
  /*  padding: 0 10px;*/
}

.dropdown-list li:hover a {
  color: #f05b55;
}

.dropdown-list li:last-of-type a {
  border: none;
}

.dropdown-list a {
  display: block;
  text-decoration: none;
  color: #333;
  padding: 10px 0;
  transition: all 0.3s ease-out;
  border-bottom: 1px solid #e6e8ea;
}

.login_btn {
  font-family: "Gotham-Pro-Regular";
  font-size: 13px;
  font-weight: 500;
  background-color: #004964;
  color: #fff;
  height: 40px;
  /*width: 183px;*/
  border-radius: 8px;
  border: 1px solid #fff;
  padding: 5px 15px 5px 20px;
  cursor: pointer;
}

/* =====================service modal start =================== */
.ServiceModal {
  position: absolute;
  top: 45px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000045;
  transition: opacity 300ms;
  visibility: visible;
  opacity: 1;
  /*height: 114vh;*/
}

.ServiceModal:target {
  visibility: visible;
  opacity: 1;
}

.service_popup_modal {
  position: relative;
  margin: 100px auto;
  padding: 30px;
  background: white;
  box-sizing: border-box;
  /*box-shadow: 10px 10px 10px 10px #eee;*/
  width: 500px;
  border-radius: 20px;
  border: 10px solid #004964;
  z-index: 20;
}

.service_popup_modal .serviceclose {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 17px;
  font-weight: 700;
  color: #043e56;
  background: #ffffff;
  height: 28px;
  width: 28px;
  text-align: center;
  border: 1px solid #043e56;
  border-radius: 50%;
}

.serviceVideoContainer {
  height: fit-content !important;
}

.login_popup_logo {
  height: 100px;
}

.service_brokers_text {
  font-family: "Gotham-Pro-Bold";
  font-weight: 600;
  font-size: 26px;
  text-align: center;
  color: #043e56;
}


.service_para {
  font-family: "Gotham-Pro-Regular";
  font-weight: 500;
  font-size: 12px;
  color: gray;
  text-align: center;
  /*  padding-top: 15px;*/
}

.service_text {
  color: #043e56;
}

/* =============================service modal end =============================*/


/*-------------------------------------------------Login popup start------------------------------------------------------------------*/
.quote {
  /*margin: 40px;
  margin-left: 800px;*/
}

.QuoteModal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000045;
  transition: opacity 300ms;
  visibility: visible;
  opacity: 1;
  /*height: 114vh;*/
}

.QuoteModal:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  /*background-color: #043A52;
  padding: 20px;*/
}

.login_popup_modal {
  position: relative;
  margin: 40px auto;
  padding: 30px;
  background: white;
  box-sizing: border-box;
  /*box-shadow: 10px 10px 10px 10px #eee;*/
  max-width: 600px;
  border-radius: 20px;
  border: 10px solid #004964;
  z-index: 20;
  /* margin-inline: auto; */
}

.login_popup_modal .quoteclose {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 17px;
  font-weight: 700;
  color: #043e56;
  background: #ffffff;
  height: 28px;
  width: 28px;
  text-align: center;
  border: 1px solid #043e56;
  border-radius: 50%;
}

.login_popup_logo {
  height: 100px;
}

.join_novi_marin_brokers_text {
  font-family: "Gotham-Pro-Bold";
  font-weight: 600;
  font-size: 28px;
  text-align: center;
  color: #043e56;
}

.signup_and_experience_para {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  font-weight: 600;
  color: #66a9c2;
  text-align: center;
}

.we_will_send_para {
  font-family: "Gotham-Pro-Regular";
  font-weight: 500;
  font-size: 16px;
  color: gray;
  text-align: center;
  /*  padding-top: 15px;*/
}

.resend_code_text {
  font-family: "Gotham-Pro-Medium";
  font-weight: 500;
  font-size: 16px;
  color: #29a6d4;
  padding-left: 20px;
  cursor: pointer;
}

.one_time_password_text {
  color: #043e56;
}

.form-group .form-control {
  font-family: "Gotham-Pro-Regular";
  font-weight: 500;
  font-size: 14px;
  color: gray;
}

.btn:hover {
  border: 1px solid #4c93ab;
}

.get_otp_btn {
  font-family: "Gotham-Pro-Regular";
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  background-color: #4c93ab;
  height: 40px;
  /*  width: 120px;*/
  margin-top: 10px;
  margin-bottom: 10px;
  text-transform: uppercase;
  padding: 8px 20px;
}

.otp {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid #d2c7c7;
  border-radius: 5px;
  text-align: center;
  background-color: #f8f8f8;
}

/* .footer {
  margin-top: 30px;
} */

/* .footer_bottom {
  height: 10px;
  background-color: #4d94ac;
} */

/*-------------------------------------------------Login popup end------------------------------------------------------------------*/
/*-----------------------------------------------------------Sign up page start-------------------------------------------------------------*/
/* HEADER STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  /*  padding: 20px 0;*/
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.15);
}

#page-header {
  position: fixed;
  top: 0px;
  width: 100%;
  box-shadow: 0px 8px 10px -15px #111;
  z-index: 1000;
  background: white;
}

.page-header nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  gap: 10px;
  /*box-shadow: 0px 8px 10px -15px #111;    */
}

.signup_logo {
  width: 140px;
}

.nav-left {
  display: flex;
}

.page-header button {
  display: flex;
  align-items: center;
  font-size: 16px;
  /*  font-weight: bold;*/
  padding: 5px 10px;
  border: 1px solid;
  border-radius: 5px;
  color: #034a63;
  background: #ffffff;
  transition: background 0.3s;
  text-transform: uppercase;
}

.help_btn {
  font-family: "Gotham-Pro-Regular";
  /*height: 40px;
  width: 81px;*/
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
  text-transform: uppercase;
  height: 43px;
}

.help_icon {
  padding-right: 5px;
}

.page-header button svg {
  flex-shrink: 0;
  margin-left: 5px;
  fill: currentColor;
}

.page-header button:hover {
  /*background: #0ab8f6;*/
}

.user-menu {
  padding-left: 0;
  list-style: none;
}

.user-menu-wrap {
  position: relative;
  /* width: 36px;*/
  margin-left: 10px;
  /*margin: 50px auto;*/
}

.menu-container {
  visibility: hidden;
  opacity: 0;
}

.menu-container.active {
  visibility: visible;
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

.mini-photo-wrapper {
  display: flex;
  /*gap: 16px;*/
}

.mini-photo {
  height: 42px;
  width: 42px;
}

.header-dropdown {
  padding: 16px 10px;
}

.user-menu {
  position: absolute;
  right: -22px;
  background-color: #ffffff;
  width: 215px;
  border-radius: 9px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 20px;
  z-index: 10;
}

.user-menu .profile-highlight {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  padding: 12px 16px;
  margin-bottom: 6px;
}

.user-menu .profile-highlight img {
  width: 48px;
  height: 48px;
  border-radius: 25px;
  -o-object-fit: cover;
  object-fit: cover;
}

.user-menu .profile-highlight .details {
  display: flex;
  flex-direction: column;
  margin: auto 12px;
}

.user-menu .profile-highlight .details #profile-name {
  font-weight: 600;
  font-size: 16px;
}

.user-menu .profile-highlight .details #profile-footer {
  font-weight: 300;
  font-size: 14px;
  margin-top: 4px;
}

.user-menu .footer {
  border-top: 1px solid #e0e0e0;
  padding-top: 6px;
  margin-top: 6px;
}

.user-menu .footer .user-menu-link {
  font-size: 13px;
}

.user-menu .user-menu-link {
  font-family: "Gotham-Pro-Regular";
  display: flex;
  text-decoration: none;
  color: #333333;
  font-weight: 400;
  font-size: 12px;
  padding: 6px 6px;
}

.user-menu .user-menu-link div {
  margin: auto 10px;
}

.user-menu .user-menu-link:hover {
  background-color: #f5f5f5;
  color: #333333;
}

.user-menu:before {
  position: absolute;
  top: -16px;
  left: 120px;
  display: inline-block;
  content: "";
  border: 8px solid transparent;
  border-bottom-color: #e0e0e0;
}

.user-menu:after {
  position: absolute;
  top: -14px;
  left: 121px;
  display: inline-block;
  content: "";
  border: 7px solid transparent;
  border-bottom-color: #ffffff;
}

/*chat box*/

.msger {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 1074px;
  margin: 125px auto;
  height: calc(100% - 50px);
  /*border: var(--border);*/
  border: 1px solid #4d94ac;
  border-radius: 15px;
  background: var(--msger-bg);
  /*box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);*/
}

.overview-msger {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 1074px;
  margin: 125px auto 50px auto;
  height: calc(100% - 50px);
  /*border: var(--border);*/
  background: var(--msger-bg);
  /*box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);*/
}

.msger-header {
  font-family: "Gotham-Pro-Bold";
  font-size: 23px;
  font-weight: 600;
  /*display: flex;*/
  justify-content: space-between;
  padding: 25px;
  border-bottom: var(--border);
  background: #4d94ac;
  color: #ffffff;
  text-align: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.msger-chat {
  flex: 1;
  overflow-y: auto;
  padding: 50px;
  /*border: 1px solid #4D94AC;*/
}

.msger-chat::-webkit-scrollbar {
  width: 6px;
}

.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}

.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}

.msg {
  display: flex;
  /*align-items: flex-end;*/
  margin-bottom: 10px;
}

.msg:last-of-type {
  margin: 0;
}

.msg-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../img/msg_left_icon.png");
  /*border-radius: 50%;*/
}

.msg-bubble {
  max-width: 474px;
  padding: 20px;
  border-radius: 8px;
  background: var(--left-msg-bg);
  border: 1px solid #034a63;
}

.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}

.msg-info-time {
  font-size: 0.85em;
}

.left-msg .msg-bubble {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  padding: 21px 30px;
  /*border-bottom-left-radius: 0;*/
  color: #034a63;
  background: #eefaff;
}

.right-msg {
  flex-direction: row-reverse;
}

.right-msg .msg-bubble {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  background: var(--right-msg-bg);
  color: #fff;
  /*border-bottom-right-radius: 0;*/
  background: #034a63;
}

.right-msg .msg-img {
  margin: 0 0 0 10px;
}

.msger-inputarea {
  display: flex;
  width: 100%;
  padding: 25px 50px;
  border-top: 1px solid #034a63;
  background: #ffffff;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.msger-inputarea * {
  padding: 8px;
  border: none;
  border-radius: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.msger-input {
  font-family: "Gotham-Pro-Regular";
  padding: 8px 20px;
  flex: 1;
  background: #f9f9f9;
  border: 1px solid #034a63;
}

.msger-send-btn {
  margin-left: 10px;
  background: #4c93ab;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.23s;
  height: 40px;
  margin: auto;
}

.msger-send-btn:hover {
  background: #4c93ab;
}

/*-----------------------------------------------------------Sign up page End-------------------------------------------------------------*/
/*-----------------------------------------------------------Sign up 2 page start-------------------------------------------------------------*/

.enter_website-btn {
  height: 50px;
  width: 160px;
  background: #29a6d4;
  color: #ffffff;
  padding: 9px;
  text-transform: uppercase;
  float: right;
}

.msg-bubble_2 {
  background: #29a6d4 !important;
  color: #ffffff !important;
  border: 1px solid #29a6d4 !important;
}

.msger-inputarea_2 {
  display: block !important;
  width: 100%;
  padding: 15px 20px;
  border-top: 1px solid #034a63;
  /*border-top: var(--border);*/
  background: #ffffff;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #3498db;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
  display: block !important;
}

/*-----------------------------------------------------------Sign up 2 page  -------------------------------------------------------------*/
/*-------------------------------------------Dashboard List View----------------------------------------------*/
aside {
  /*  background:#1a1d23; */
  /*  width:250px;*/
  /*  height:100vh; */
  position: fixed;
  transition: 0.3s;
  /*  left:-250px;*/
  top: 0;
  /*  transition-timing-function: cubic-bezier(0.9,0,1,1);*/
}

aside.close {
  left: 0;
  transition: 0.3s;
  transition-timing-function: cubic-bezier(0.9, 0, 1, 1);
}

aside .toggle {
  border: none;
  background: none;
  position: absolute;
  right: -40px;
  top: 7px;
  font-size: 30px;
  transform: rotate(90deg);
  display: inline-block;
  cursor: pointer;
}

aside.close {
  left: 0;
  transition: 0.3s;
  transition-timing-function: cubic-bezier(0.9, 0, 1, 1);
}

.heart {
  position: absolute;
  top: 0px;
  right: 0px;
  /* background: white; */
  padding: 3px 5px 0px 5px;
  border-radius: 30px;
}

.fa-heart-o {
  color: #707070;
  cursor: pointer;
}

.fa-heart {
  color: red;
  cursor: pointer;
}

.left-sidebar {
  /*position: fixed;*/
  /*height: 100vw;*/
  width: 374px;
  background-color: #ffffff;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.09);
  float: left;
  height: 690px;
  overflow-y: auto;
}

.left-sidebar.opened {
  -webkit-transform: translate(0);
  transform: translate(0);
}

.toggle-btn {
  position: absolute;
  left: 320px;
  top: 10px;
  z-index: 5;
}

.search_field {
  position: relative;
  width: 100%;
  /*    height: 50px;*/
  padding: 20px 26px;
  border-bottom: 1px solid #cfcfcf;
}

.search_field .search {
  font-family: "Gotham-Pro-Regular";
  width: 100%;
  height: 45px;
  padding-left: 45px;
  padding-right: 30px;
  font-size: 12px;
  letter-spacing: 1px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #aaa;
}

.search_field span {
  position: absolute;
  top: 50%;
  /*    right: 0;*/
  transform: translateY(-58%);
  font-size: 24px;
  color: #aaa;
  cursor: pointer;
  padding: 12px;
  border-radius: 0 5px 5px 0;
  transition: all 0.3s;
}

.search_field span:hover {
  /*    background-color: #AAA;*/
  color: #000;
}

.search_field_header {
  position: relative;
  width: 90%;
  /*    height: 50px;*/
  padding: 20px 12px;
  /*    border-bottom: 1px solid #CFCFCF;*/
  float: left;
  z-index: -1;
}

.search_field_header .search {
  font-family: "Gotham-Pro-Regular";
  width: 100%;
  height: 45px;
  padding-left: 45px;
  padding-right: 30px;
  font-size: 12px;
  letter-spacing: 1px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #aaa;
}

.search_field_header span {
  position: absolute;
  top: 50%;
  /*    right: 0;*/
  transform: translateY(-58%);
  font-size: 24px;
  color: #aaa;
  cursor: pointer;
  padding: 12px;
  border-radius: 0 5px 5px 0;
  transition: all 0.3s;
}

.search_field_header span:hover {
  /*    background-color: #AAA;*/
  color: #000;
}

.data_container {
  margin-top: 20px;
  padding: 0 20px;
}

.data_container .item {
  width: 100%;
  height: 40px;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 10px;
  padding: 0 15px;
  display: flex;
  align-items: center;
}

.filters_text {
  font-family: "Gotham-Pro-Bold";
  font-size: 18px;
  padding: 10px;
  text-transform: uppercase;
}

#mobile-menu {
  /*    border-top: 1px solid #2D2D2D;*/
  margin: 16px;
}

#mobile-menu.mobile-hide-icons i.fa {
  display: none;
}

#mobile-menu .mega-menu-block {
  padding: 0 !important;
  background: transparent !important;
  min-height: inherit !important;
}

#mobile-menu ul ul,
#slide-out #mobile-menu .sub-menu-columns-item {
  display: none;
  /*    background: #333;*/
}

#mobile-menu ul ul.sub-menu li {
  margin: 0;
}

#mobile-menu li {
  list-style: none;
  position: relative;
  margin: 10px;
  background: #f9f9f9;
  border-radius: 5px;
}

.menu-item-has-children {
  border: 1px solid #878787;
  border-radius: 5px;
  margin: 10px;
}

#mobile-menu ul li.menu-item-has-children i.mobile-arrows {
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px;
  /*    border-left: 1px solid #333;*/
  margin: 0;
  cursor: pointer;
  display: block;
}

#mobile-menu a {
  font-family: "Gotham-Pro-Regular";
  font-size: 12px;
  color: #878787;
  display: block;
  padding: 12px;
  padding-right: 0;
  /*    border-bottom: 1px solid #2D2D2D;*/
}

#mobile-menu a:hover {
  /*background: #111;
    color: #FFF;*/
  text-decoration: none;
}

#mobile-menu ul ul a {
  font-size: 14px;
  padding-left: 30px;
}

#mobile-menu ul ul ul a {
  font-size: 12px;
  padding-left: 40px;
}

#mobile-menu ul ul ul a {
  padding-left: 50px;
}

#mobile-menu ul ul ul ul a {
  padding-left: 60px;
}

#mobile-menu li.menu-item-home a:before {}

.main-menu ul {
  list-style: none;
  padding-left: 0;
}

.price_range_text {
  font-family: "Gotham-Pro-Medium";
  font-size: 12px;
  padding: 20px 20px 0 20px;
}

.price_row {
  width: 100%;
  display: flex;
  padding: 0 10px;
  margin-bottom: 15px;
}

.input-field {
  font-family: "Gotham-Pro-Regular";
  font-size: 12px;
  padding: 10px;
  width: 50%;
  border-radius: 5px;
  border: 1px solid #cfcfcf;
  margin: 3px;
}

.search_btn {
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  color: #ffffff;
  height: 44px;
  width: -webkit-fill-available;
  border: 1px solid #29a6d4;
  border-radius: 5px;
  background: #4d94ac;
  margin: 10px;
  cursor: pointer;
}

.items_for_sale_btn {

  font-family: "Gotham-Pro-Regular";

  font-size: 16px;

  font-weight: 500;

  height: 40px;

  width: auto;
  white-space: nowrap;
  padding-inline: 15px;

  border: 1px solid #034a63;

  color: #034a63;

  border-radius: 5px;

  cursor: pointer;

  /*margin-left: 40px;*/

}



.items_wanted_btn {

  font-family: "Gotham-Pro-Regular";

  font-size: 16px;

  font-weight: 500;

  height: 40px;

  width: auto;
  white-space: nowrap;
  padding-inline: 15px;

  border: 1px solid #034a63;

  color: #034a63;

  border-radius: 5px;

  cursor: pointer;

}

.items_for_sale_btn.active,
.items_wanted_btn.active {
  background: #034a63;
  color: #ffffff;
  border: 1px solid #ffffff;
}

.section {
  position: relative;
  top: 95px;
  /*display: -webkit-inline-box;*/
}

.right {
  position: absolute;
  padding: 28px;
  width: -webkit-fill-available;
  left: 300px;
  background: #f9f9f9;
}

.items_for_sale_text {
  font-family: "Gotham-Pro-Bold";
  font-weight: 500;
  font-size: 18px;
  padding-top: 20px;
  color: #034a63;
  margin-bottom: 0px;
}

.your_searched_results_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  color: #878787;
}

.items_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 18px;
  color: #4d94ac;
}

.filter_applied_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 12px;
  color: #878787;
}

.icon_map {
  background: white;
  padding: 10px;
  border: 1px solid #4d94ac;
  border-radius: 4px;
  float: right;
  /*  margin-bottom: 20px;*/
  margin-top: 14px;
  cursor: pointer;
}

.icon_list {
  position: absolute;
  top: 40px;
  z-index: 999;
  right: 25px;
  background: #034a63;
  padding: 12px;
  border: 1px solid #4d94ac;
  border-radius: 4px;
}

.sell_btn {
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  color: #ffffff !important;
  height: 43px;
  width: 120px;
  background: #fe7620 !important;
  border: 1px solid #ffffff !important;
  text-align: center;
  display: block !important;
  margin-left: 50px;
  margin-top: 15px;
  cursor: pointer;
  border-radius: 5px;
  text-transform: uppercase;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:focus {
  text-decoration: none;
}

button {
  cursor: pointer;
}

button:focus {
  /*  border: 0;*/
  outline: none;
}

.sell_btn:focus {
  border: none;
}

.buy_btn {
  font-family: "Gotham-Pro-Regular";
  font-style: 16px;
  color: #ffffff !important;
  height: 43px;
  width: 120px;
  background: #a5db63 !important;
  border: 1px solid #ffffff !important;
  text-align: center;
  display: block !important;
  margin-left: 15px;
  margin-top: 15px;
  cursor: pointer;
  border-radius: 5px;
  text-transform: uppercase;
}

.sell_icon {
  padding-right: 5px;
}

.item_card {
  background: #ffffff;
  padding: 5px;
  border-radius: 9px;
  margin-bottom: 10px;
  height: 100%;
  box-shadow: 0px 9px 13px rgba(0, 0, 0, 0.09);
}

.item_name {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
  color: black;
}

.item_price {
  font-family: "Gotham-Pro-Regular";
  color: #4d94ac;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 16px;
}

.listing_id_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  color: #034a63;
  font-weight: 600;
}

.item_card_hr {
  border: 1px solid #cbcbcb;
}

.length_width {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  color: #878787;
  margin-bottom: 5px;
}

.like_btn {
  /*height:100px;*/
  /*margin:0 auto;*/
  position: absolute;
  top: 0;
  right: 30px;
}

.like_icon {
  position: relative;
  cursor: pointer;
  padding: 4px 4px 4px;
  background: #fff;
  border-radius: 50%;
  display: inline-block;
  margin: 0 0 15px;
  color: #aaa;
  transition: 0.2s;
  top: 20px;
  height: 20px;
  width: 20px;
}

.like_icon:hover {
  color: red;
}

.like_icon:before {
  font-family: fontawesome;
  content: "\f004";
  font-style: normal;
  font-size: 10px;
}

.liked_text {
  position: absolute;
  bottom: 70px;
  left: 0;
  right: 0;
  visibility: hidden;
  transition: 0.6s;
  z-index: -2;
  font-size: 2px;
  color: transparent;
  font-weight: 400;
}

.like_icon.press {
  animation: size 0.4s;
  color: red;
}

.liked_text.press {
  bottom: 120px;
  font-size: 14px;
  visibility: visible;
  animation: fade 1s;
}

/* @keyframes fade {
  0% {color:#transparent;}
  50% {color:#e23b3b;}
  100% {color:#transparent;}
} */

@keyframes size {
  0% {
    padding: 10px 12px 8px;
  }

  50% {
    padding: 14px 16px 12px;
    margin-top: -4px;
  }

  100% {
    padding: 10px 12px 8px;
  }
}

.select {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 100px;
  max-width: 220px;
  /*  width:139px;*/
  /*  height:35px;*/
  border: 1px solid #878787;
  border-radius: 4px;
  /*  margin-top: 20px;*/
}

.option {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  color: #878787;
  padding: 0 30px 0 10px;
  min-height: 30px;
  display: flex;
  align-items: center;
  background: #ffffff !important;
  /*border-top:#222 solid 1px;*/
  position: absolute;
  top: 0;
  width: 100%;
  pointer-events: none;
  order: 2;
  z-index: 1;
  transition: background 0.4s ease-in-out;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0;
}

.option:hover {
  background: #666;
}

.select:focus .option {
  position: relative;
  pointer-events: all;
}

.select input {
  opacity: 0;
  position: absolute;
  left: -99999px;
}

input:checked+label h1 {
  /* order: 1;
  z-index:2;
  background:#29A6D4!important;
  border-top:none;
  position:relative;*/
  color: #ffffff;
}

input:checked+label:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #878787;
  position: absolute;
  right: 10px;
  top: calc(50% - 2.5px);
  pointer-events: none;
  z-index: 3;
}

input:checked+label:before {
  position: absolute;
  right: 0;
  height: 35px;
  width: 35px;
  content: "";
  background: #fff;
}

.sticky_btn {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000;
}

/*popup*/
/* The Modal (background) */
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1300;
  /* Sit on top */
  padding-top: 35px;
  /* Location of the box */
  padding-top: 35px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.8);
  /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  /*padding: 20px;*/
  border: 1px solid #888;
  width: 90%;
}

/* The Close Button */
.close {
  position: absolute;
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  z-index: 1000;
  color: black;
  right: 0;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/*----------------------------------------------Dshboard Home page start----------------------------------------------*/
/*#sidebar {
  position:absolute;
  top:0px;
  left:0px;
  width:200px;
  height:100%;
  background:#151719;
  transition:all 300ms linear;
}
#sidebar.active {
  left:-200px;
}
#sidebar .toggle-btn {
  position:absolute;
  left:220px;
  top:10px;
}
#sidebar .toggle-btn span {
  display:block;
  width:30px;
  height:5px;
  background:#151719;
  margin:5px 0px;
  cursor:pointer;
}
#sidebar div.list div.item {
  padding:15px 10px;
  border-bottom:1px solid #444;
  color:#fcfcfc;
  text-transform:uppercase;
  font-size:12px;
}

#sidebar div.list div.item a{
  color:#fcfcfc;
  text-decoration: none;
}*/

/*----------------------------------------------------------------sell popup------------------------------------------------*/
.overlay_2 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  transition: opacity 500ms;
  z-index: 999999;
  overflow-y: scroll;
}

.overlay_2:target {
  visibility: visible;
  opacity: 1;
}

.popup1 {
  margin: 50px auto;
  /*padding: 20px;*/
  background: #fff;
  border-radius: 22px;
  width: 50%;
  max-width: 585px;
  position: relative;
  /*  transition: all 5s ease-in-out;*/
  /*  min-height: 400px;*/
  /*  height: -webkit-fill-available;*/
}

.popup-content {
  padding: 20px;
}

.popup1 h2 {
  margin-top: 0;
  color: #333;
  /*font-family: Tahoma, Arial, sans-serif;*/
}

.popup1 .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  background: transparent;
  color: white;
  border: 2px solid white;
  border-radius: 50px;
  opacity: 1;
  line-height: 0;
}

.popup1 .close:hover {
  color: #06d85f;
}

.popup1 .close img {
  position: relative;
  /*    top: -4px;*/
  padding: 7px 7px;
}

.popup1 .content {
  /*  max-height: 30%;*/
  overflow: auto;
  padding: 15px;
  padding: 20px 40px;
}

.hide-list {
  display: none;
}

/* Product Detail Popup CSS Start */
.full-width-popup {
  width: 100%;
  max-width: 95vw;
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.column {
  float: left;
  width: 20%;
  padding: 10px;
}

.mySlides {
  display: none;
}

.mySlides.active {
  display: block;
}

/* Product Detail Popup CSS End */

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}

.prev {
  height: 50px;
  width: 50px;
  padding: 10px;
  text-align: center;
  margin-left: -86px;
  background: white;
  border-radius: 50%;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
  height: 50px;
  width: 50px;
  padding: 10px;
  text-align: center;
  margin-right: -86px;
  background: white;
  border-radius: 50%;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.custom-close-btn {
  height: 40px;
}

.custom-close-btn .close img {
  filter: brightness(0);
}

.items_description_para {
  font-family: "Gotham-Pro-Regular";
  font-size: 12px;
  color: #000000;
}

svg.svg-inline--fa.fa-arrow-right.fa-w-14 {
  background: #fff;
  font-size: 21px;
  padding: 2px;
}

.pac-container {
  z-index: 1100 !important;
}

#info_popup iframe {
  width: 100%;
  height: 500px;
}

#info_popup .popup1 .close {
  top: 8px;
  right: 10px;
}

button.ct_map_icon_12 {
  display: none;
}

@media screen and (max-width: 767px) {
  .section {
    position: relative;
    /* top: 70px; */
    /* display: -webkit-inline-box; */
  }

  button.ct_map_icon_12 {
    background: #034a63;
    padding: 8px 10px;
    color: #fff;
    display: flex;
    align-items: center;
    display: block;
    gap: 6px;
    justify-content: center;
    height: 40px;
    width: 89px;
    text-align: center;
    border: none;
    border-radius: 5px;
  }

  .page-header nav {
    padding-inline: 0px !important;
    padding-top: 0px;
  }

  .ct_mt_767px {
    margin-top: 150px;
  }

  .popup1 {
    width: 100%;
    margin: 0px auto;
    padding: 0px;
    background: #fff;
    border-radius: 0px;
    height: 100%;
    /* width: 50%; */
    max-width: 100%;
    position: relative;
    /*    transition: all 5s ease-in-out;*/
    /* min-height: 400px; */
  }

  .popup1-header {
    height: px;
    background: #4d94ac;
    text-align: center;
    border-radius: 0px 0px 0 0 !important;
  }

}

.popup1-header {
  height: px;
  background: #4d94ac;
  text-align: center;
  border-radius: 22px 22px 0 0;
}

.sell_popup_logo {
  height: 90px;
  padding: 15px;
}

.embed {
  height: 200px;
  width: -webkit-fill-available;
  background: #4d94ac;
  margin: 10px 10%;
  border-radius: 18px;
}

.popup_video {
  margin: 5px 0;
}

.before_moving_forward_text {
  font-family: "Gotham-Pro-Medium";
  font-size: 12px;
  text-align: center;
  color: #034a63;
}

.before_moving_forward_text span {
  color: #29a6d4;
}

/**/
.bg {
  background: url("../img/product_detail/tn_fishing_boat_for_sale_226931.png") no-repeat center center fixed;
  /* Full height */
  /*background: #00000073;*/
  height: 100%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 15% 22%;
}

@media only screen and (max-width: 992px) {
  .bg {
    padding: 9% 0;
  }

  /*.left-sidebar{

  }*/
  /*.right{
    left: 0;
  }*/
}

@media only screen and (max-width: 767px) {
  .right {
    left: 0;
  }

  .mob_view {
    top: 150px;
  }

  .icon_map_header {
    background: #034a63;
    padding: 12px;
    border: 1px solid #4d94ac;
    border-radius: 4px;
    float: right;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .icon_map {
    display: none;
  }

  .modal {
    padding-top: 0px;
  }

  .modal-content {
    margin-top: 39px;
  }

  .column {
    padding: 1px !important;
  }
}

.right_sidebar {
  width: 100%;
}

.item-slick.slick-slide.slick-current.slick-active {
  outline: none !important;
}

.slider-for {
  margin-bottom: 15px;
}

.slider-for img {
  width: 100%;
  min-height: 100%;
}

.slider-nav {
  margin: auto;
}

.slider-nav .item-slick {
  max-width: 240px;
  margin-right: 15px;
  outline: none !important;
  cursor: pointer;
}

.slider-nav .item-slick img {
  max-width: 100%;
  background-size: cover;
  background-position: center;
}

.slick-arrow {
  position: absolute;
  top: 50%;
  z-index: 50;
  margin-top: -12px;
}

.slick-prev {
  left: 0;
}

.slick-next {
  right: 0;
}

.logo_3 {
  width: 122px;
}

.product_detail_nav {
  border-bottom: 1px solid #d9d9d9;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
}

.right_menu {
  gap: 15px;
  line-height: 65px;
}

.fiberglass_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 26px;
  color: #000000;
}

.fiberglass_price {
  font-family: "Gotham-Pro-Regular";
  font-size: 26px;
  color: #4d94ac;
  margin-bottom: 5px;
}

.listing_id {
  font-family: "Gotham-Pro-Regular";
  font-size: 18px;
  color: #034a63;
  margin-bottom: 5px;
}

.Commercial_boat_only_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  margin-bottom: 5px;
}

.Contact_us_btn {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  color: #ffffff;
  height: 42px;
  width: 100%;
  background: #3d670a;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.product_detail_2 {
  padding: 10px 30px;
}

ul.menu {
  padding: 0;
  list-style: none;
  width: 100%;
  /*margin: 20px auto;*/
  /*font-family: "Century Gothic";*/
  /*box-shadow: 0px 0px 25px #00000070;*/
  clear: both;
  display: table;
  /*  margin-bottom: 100px;*/
  padding: 10px 30px;
}

ul.menu .list {
  font-size: 14px;
  border-bottom: 1px solid #324252;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  height: 50px;
  vertical-align: sub;
  background: #4d94ac;
  clear: both;
  border-radius: 6px 6px;
  margin-bottom: 10px;
}

ul.menu .list:after {
  content: "\f078";
  font-family: FontAwesome;
  position: absolute;
  right: 17px;
  top: 12px;
  padding: 1px 5px;
  color: #4d94ac;
  height: 24px;
  width: 24px;
  background: #ffffff;
  border-radius: 30px;
  cursor: pointer;
}

ul.menu .list:before {
  /*content: "";
  font-family: FontAwesome;
  position: absolute;
  left: 17px;
  top: 17px;
  padding: 0px 5px;
  color: #fff;*/
}

ul.menu .list a {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  text-decoration: none;
  color: #fff;
  padding: 14px 0px 14px 14px;
  display: block;
  height: 100%;
  box-sizing: border-box;
}

ul.menu .list a:hover {
  /*background-color: #4D94AC;*/
  transition: 300ms all;
  color: #ffffff;
  border-radius: 6px;
}

ul.menu .list .items {
  height: 0px;
  overflow: hidden;
}

ul.menu .list .items a {
  /*  padding: 17px;*/
}

ul.menu .list .items a:hover {
  /*background-color: #3f5d79;*/
  color: #fff;
  transition: 300ms all;
  border-radius: 6px;
}

ul.menu .list:last-child {
  border-bottom: none;
}

ul.menu .active:after {
  display: none;
  content: "\f078";
  font-family: FontAwesome;
  position: absolute;
  right: 17px;
  top: 12px;
  padding: 1px 5px;
  color: #4d94ac;
  height: 24px;
  width: 24px;
  transform: rotate(180deg);
}

/*ul.menu .active:before {
  content: "";
  font-family: FontAwesome;
  position: absolute;
  left: 17px;
  top: 17px;
  padding: 0px 5px;
  color: #fff;
}*/
ul.menu .active>.items {
  display: block;
  background: #f9f9f9;
  padding: 15px;
  height: auto;
  color: #fff;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: all 200ms;
  clear: both;
  float: left;
  width: 100%;
}

ul.menu .active>.items li {
  padding: 0px;
  border-bottom: 1px solid #324252;
  list-style: none;
}

ul.menu .active>.items li:last-child {
  border-color: transparent;
  padding-bottom: 0px;
}

ul.menu .active>.items .active>.items {
  background-color: #2f4b67;
}

ul.menu .active>a {
  color: #ffffff;
  /*text-transform: uppercase;*/
  font-weight: bold;
  border-radius: 6px;
}

ul.menu .active .list {
  background: #697d92;
}

ul.menu .active .list a {
  padding: 17px 0px 17px 45px;
}

.items_description_para {
  font-family: "Gotham-Pro-Regular";
  font-size: 12px;
  color: #000000;
}

.type_of_construction_text {
  font-family: "Gotham-Pro-Bold";
  font-size: 12px;
  color: #000000;
}

.check_item {
  font-family: "Gotham-Pro-Regular";
  font-size: 10px;
  color: #000000;
  line-height: 22px;
  margin-bottom: 0;
}

.check_icon {
  padding-right: 10px;
}

.engine_hours_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 10px;
  color: #878787;
  line-height: 22px;
  margin-bottom: 0;
}

.main-img {
  display: flex;
  width: 400px;
  height: 300px;
  overflow: hidden;
  perspective: 600px;
  background: url("../img/product_detail/tn_fishing_boat_for_sale_226931.png") center/cover;
}

.images {
  margin-top: 0.5rem;
}

.main-img span {
  display: block;
  width: 60px;
  height: 250px;
  background-position: center;
  background-size: cover;
}

.effect {
  animation: move 0.6s forwards;
}

@keyframes move {
  0% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(360deg);
  }
}

.embed_2 {
  width: 100%;
  height: 350px;
}

.popup1-header p {
  font-family: "Gotham-Pro-Regular";
  font-size: 20px;
  text-align: left;
  color: #ffffff;
  padding: 30px;
}

/*gallery lightbox*/
.modal-backdrop.in {
  filter: alpha(opacity=80);
  opacity: 0.8;
}

.popup-image-gallery .modal-dialog {
  width: 100%;
}

.popup-image-gallery .modal-content {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
}

.popup-slider-for {
  position: relative;
  min-height: 300px;
  z-index: 2;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.popup-slider-for:after {
  content: "\e030";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  z-index: -1;
  color: #fff;
  opacity: 0.75;
}

.popup-slider-for,
.main-image {
  text-align: center;
  margin-bottom: 0;
}

.popup-slider-for .slick-slide img {
  display: inline-block;
  max-width: 100%;
  vertical-align: middle;
}

.popup-slider-nav .thumbnail-image {
  position: relative;
  margin: 0 5px;
  cursor: pointer;
}

.popup-slider-nav .thumbnail-image,
.popup-slider-nav .thumbnail-image img {
  display: block;
  width: 140px;
  height: 100px;
}

.popup-slider-nav .thumbnail-image:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.popup-slider-nav .thumbnail-image:hover:after {
  background-color: rgba(0, 0, 0, 0.4);
}

.popup-slider-nav .slick-current:after,
.popup-slider-nav .slick-current:hover:after,
.popup-slider-nav .slick-center:after,
.popup-slider-nav .slick-center:hover:after {
  background-color: transparent;
}

.pagingInfo {
  color: #fff;
  text-align: center;
  margin: 20px 0;
}

.popup-slider-for .slick-next,
.popup-slider-for .slick-prev {
  width: auto;
  height: auto;
  z-index: 1;
}

.popup-slider-for .slick-prev {
  left: 0;
}

.popup-slider-for .slick-prev:before,
.popup-slider-for .slick-next:before,
.popup-slider-for:after {
  font: normal normal 30px/1 "Glyphicons Halflings";
}

.popup-slider-for .slick-prev:before {
  content: "\e257";
}

.popup-slider-for .slick-next:before {
  content: "\e258";
}

.popup-slider-for .slick-next {
  right: 0;
}

.popup-image-gallery .close-icon {
  top: 0;
}

.slick-slide {
  outline: 0;
}

.close-btn {
  background-color: transparent;
  color: #fff;
  position: absolute;
  right: 30px;
  z-index: 3;
  font-size: 24px;
  margin: 0;
  opacity: 0.75;
}

.close-btn:hover,
.close-btn:focus {
  color: #fff;
  opacity: 10;
}

.popup-image-gallery .modal-dialog {
  margin: 0;
}

@media (max-width: 767px) {
  .popup-image-gallery .modal-body {
    padding: 0;
  }

  .popup-image-gallery .modal-dialog {
    margin-top: 20px;
  }

  .popup-image-gallery .close-icon {
    top: -20px;
  }

  .popup-slider-for .slick-prev {
    left: 12px;
  }

  .popup-slider-for .slick-next {
    right: 12px;
  }
}

#close-sidebar-popup {
  position: absolute;
  left: 361px;
  top: 40px;
}

#open-sidebar-popup {
  position: absolute;
}

.lightbox {
  /* Default to hidden */
  /*display: none;*/

  /* Overlay entire screen */
  /*position: fixed;*/
  /*z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;*/

  /* A bit of padding around image */
  /*padding: 1em;*/

  /* Translucent background */
  /*background: rgba(0, 0, 0, 0.8);*/
}

/* Unhide the lightbox when it's the target */
.lightbox:target {
  /*display: block;*/
}

.lightbox span {
  /* Full width and height */
  /*display: block;
  width: 100%;
  height: 100%;*/

  /* Size and position background image */
  /*background-position: center;
  background-repeat: no-repeat;
  background-size: contain;*/
}

/*----------------------------------------------sell 2----------------------------------------------*/
.good_day_para {
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  /*  padding-bottom: 10px;*/
}

.lease_btn {
  height: 42px;
  width: 113px;
  background: #29a6d4;
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  color: #ffffff;
  border: 1px solid #29a6d4;
  border-radius: 6px;
}

/*.Sell_btn{
  height: 42px;
  width: 113px;
  background: #E8FAFF;
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  color: #29A6D4;
  border: 1px solid #29A6D4;
  border-radius: 6px;
  margin-left: 15px;
}*/

.back_btn {
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  color: #878787;
  border: 1px solid #878787;
  background: transparent;
  padding: 10px 15px;
}

.next_btn {
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  color: #ffffff;
  border: none;
  background: #4d94ac;
  padding: 10px 15px;
  margin-left: 10px;
}

.button-wrap {
  position: relative;
  /*text-align: center;*/
  /*  top: 50%;*/
  /*min-height: 200px;*/
  /*margin-top: -2.5em;*/
}

@media (max-width: 40em) {
  .button-wrap {
    /*    margin-top: -1.5em;*/
  }
}

.button-wrap_2 {
  position: relative;
  /*text-align: center;*/
  /*  top: 50%;*/
  /*min-height: 200px;*/
  /*margin-top: -2.5em;*/
}

.option_btn {
  position: relative;
}

.option_btn input {
  opacity: 0;
  position: absolute;
}

.option_btn input:checked+span {
  background-color: #ffc107;
}

.option_btn input:checked+span:hover,
.option input:checked+span:focus,
.option input:checked+span:active {
  background-color: #ffc107;
}

.option_btn .btn-option {
  margin: 0 10px 10px 0;
  display: flex;
  background-color: transparent;
}

.option_btn .btn-option:hover,
.option .btn-option:focus,
.option .btn-option:active {
  background-color: transparent;
}

.button-label:before {
  height: 0;
  display: none !important;
}

.button-label:after {
  display: none !important;
}

.button-label {
  font-family: "Gotham-Pro-Regular";
  font-size: 13px;
  display: inline-block;
  padding: 10px 15px;
  /*margin: 0.5em;*/
  cursor: pointer;
  color: #29a6d4;
  border-radius: 0.25em;
  background: #eefbff;
  /*box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2), inset 0 -3px 0 rgba(0, 0, 0, 0.22);*/
  transition: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 42px;
  /* width: 126px; */
  min-width: 126px;
  max-width: auto;
  text-align: center;
  border: 1px solid #29a6d4;
  margin: 5px;
}

.button-label_3 {
  width: 180px;
}

.button-label h1 {
  font-size: 13px;
  line-height: inherit;
  margin: 0;
  color: #29a6d4;
  /*font-family: "Lato", sans-serif;*/
}

.button-label:hover {
  background: #eefbff;
  color: #ffffff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2), inset 0 -3px 0 rgba(0, 0, 0, 0.32);
}

.button-label:active {
  transform: translateY(2px);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2),
    inset 0px -1px 0 rgba(0, 0, 0, 0.22);
}

.tooltip {
  position: relative;
  z-index: 10000000 !important;
}


@media (max-width: 40em) {
  .button-label {
    /*    padding: 0em 1em 3px;*/
    margin: 0.25em;
    /* width: auto; */
    padding: 10px 5px;
  }
}

#yes-button:checked+.button-label {
  background: #29a6d4;
  color: #efefef;
  text-align: center;
}

#yes-button:checked+.button-label:hover {
  background: #29a6d4;
  color: #efefef;
}

#no-button:checked+.button-label {
  background: #29a6d4;
  color: #efefef;
}

#no-button:checked+.button-label:hover {
  background: #29a6d4;
  color: #efefef;
}

#lease-button:checked+.button-label {
  background: #29a6d4;
  color: #efefef;
}

#lease-button:checked+.button-label:hover {
  background: #29a6d4;
  color: #efefef;
}

#maybe-button:checked+.button-label {
  background: #29a6d4;
  color: #efefef;
}

#maybe-button:checked+.button-label:hover {
  background: #29a6d4;
  color: #efefef;
}

#vessel-button:checked+.button-label {
  background: #29a6d4;
  color: #efefef;
}

#vessel-button:checked+.button-label:hover {
  background: #29a6d4;
  color: #efefef;
}

#licence-button:checked+.button-label {
  background: #29a6d4;
  color: #efefef;
}

#licence-button:checked+.button-label:hover {
  background: #29a6d4;
  color: #efefef;
}

#pleasure-button:checked+.button-label {
  background: #29a6d4;
  color: #efefef;
}

#pleasure-button:checked+.button-label:hover {
  background: #29a6d4;
  color: #efefef;
}

#equipment-button:checked+.button-label {
  background: #29a6d4;
  color: #efefef;
}

#equipment-button:checked+.button-label:hover {
  background: #29a6d4;
  color: #efefef;
}

#sea-button:checked+.button-label {
  background: #29a6d4;
  color: #efefef;
}

#sea-button:checked+.button-label:hover {
  background: #29a6d4;
  color: #efefef;
}

#yes-button-2:checked+.button-label {
  background: #29a6d4;
  color: #efefef;
  text-align: center;
}

#yes-button-2:checked+.button-label:hover {
  background: #29a6d4;
  color: #efefef;
}

#no-button-2:checked+.button-label {
  background: #29a6d4;
  color: #efefef;
}

#no-button-2:checked+.button-label:hover {
  background: #29a6d4;
  color: #efefef;
}

.hidden {
  display: none;
}

.popup_input {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  height: 38px;
  width: 100%;
  border: 1px solid #878787;
  border-radius: 5px;
  background: #fbfbfb;
  padding: 10px;
  margin: 10px 0;
}

.popup_input_label {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  color: #434343;
}

/*font Variables*/
/*Color Variables*/
@import url("https://fonts.googleapis.com/css?family=Roboto:300i,400,400i,500,700,900");

.multi_step_form {
  background: #f6f9fb;
  display: block;
  overflow: hidden;
  border-radius: 0 0 22px 22px;
  /*  height: 100%;*/
}

.multi_step_form #msform {
  /*text-align: center;*/
  position: relative;
  /*padding-top: 50px;*/
  /*  min-height: 400px;*/
  /*  max-width: 810px;*/
  margin: 0 auto;
  background: #ffffff;
  z-index: 1;
  /*  height: 100%;*/
}

.multi_step_form #msform .tittle {
  text-align: center;
  padding-bottom: 55px;
}

.multi_step_form #msform .tittle h2 {
  font: 500 24px/35px "Roboto", sans-serif;
  color: #3f4553;
  padding-bottom: 5px;
}

.multi_step_form #msform .tittle p {
  font: 400 16px/28px "Roboto", sans-serif;
  color: #5f6771;
}

.overlay_2 fieldset {
  border: 0;
}

.multi_step_form #msform fieldset {
  border: 0 !important;
  padding: 20px 35px;
  position: relative !important;
  /* height: 100%; */
  width: 100%;
  left: 0;
  right: 0;
  /*transform: none!important;
  height: 96%;*/
  overflow-y: scroll;
}

.multi_step_form #msform fieldset .conten {
  /*  height: 91%;*/
  height: 100%;
}

.multi_step_form #msform fieldset:not(:first-of-type) {
  display: none;
}

.multi_step_form #msform fieldset h3 {
  font: 500 18px/35px "Roboto", sans-serif;
  color: #3f4553;
}

.multi_step_form #msform fieldset h6 {
  font: 400 15px/28px "Roboto", sans-serif;
  color: #5f6771;
  padding-bottom: 30px;
}

.multi_step_form #msform fieldset .intl-tel-input {
  display: block;
  background: transparent;
  border: 0;
  box-shadow: none;
  outline: none;
}

.multi_step_form #msform fieldset .intl-tel-input .flag-container .selected-flag {
  padding: 0 20px;
  background: transparent;
  border: 0;
  box-shadow: none;
  outline: none;
  /*  width: 65px;*/
}

.multi_step_form #msform fieldset .intl-tel-input .flag-container .selected-flag .iti-arrow {
  border: 0;
}

.multi_step_form #msform fieldset .intl-tel-input .flag-container .selected-flag .iti-arrow:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  font: normal normal normal 24px/7px Ionicons;
  color: #5f6771;
}

.multi_step_form #msform fieldset #phone {
  padding-left: 80px;
}

.multi_step_form #msform fieldset .form-group {
  padding: 0 10px;
}

.multi_step_form #msform fieldset .fg_2,
.multi_step_form #msform fieldset .fg_3 {
  padding-top: 10px;
  display: block;
  overflow: hidden;
}

.multi_step_form #msform fieldset .fg_3 {
  padding-bottom: 70px;
}

.multi_step_form #msform fieldset .form-control,
.multi_step_form #msform fieldset .product_select {
  border-radius: 3px;
  border: 1px solid #d8e1e7;
  padding: 0 20px;
  height: auto;
  font: 400 15px/48px "Roboto", sans-serif;
  color: #5f6771;
  box-shadow: none;
  outline: none;
  width: 100%;
}

.multi_step_form #msform fieldset .form-control.placeholder,
.multi_step_form #msform fieldset .product_select.placeholder {
  color: #5f6771;
}

.multi_step_form #msform fieldset .form-control:-moz-placeholder,
.multi_step_form #msform fieldset .product_select:-moz-placeholder {
  color: #5f6771;
}

.multi_step_form #msform fieldset .form-control::-moz-placeholder,
.multi_step_form #msform fieldset .product_select::-moz-placeholder {
  color: #5f6771;
}

.multi_step_form #msform fieldset .form-control::-webkit-input-placeholder,
.multi_step_form #msform fieldset .product_select::-webkit-input-placeholder {
  color: #5f6771;
}

.multi_step_form #msform fieldset .form-control:hover,
.multi_step_form #msform fieldset .form-control:focus,
.multi_step_form #msform fieldset .product_select:hover,
.multi_step_form #msform fieldset .product_select:focus {
  border-color: #5cb85c;
}

.multi_step_form #msform fieldset .form-control:focus.placeholder,
.multi_step_form #msform fieldset .product_select:focus.placeholder {
  color: transparent;
}

.multi_step_form #msform fieldset .form-control:focus:-moz-placeholder,
.multi_step_form #msform fieldset .product_select:focus:-moz-placeholder {
  color: transparent;
}

.multi_step_form #msform fieldset .form-control:focus::-moz-placeholder,
.multi_step_form #msform fieldset .product_select:focus::-moz-placeholder {
  color: transparent;
}

.multi_step_form #msform fieldset .form-control:focus::-webkit-input-placeholder,
.multi_step_form #msform fieldset .product_select:focus::-webkit-input-placeholder {
  color: transparent;
}

.multi_step_form #msform fieldset .product_select:after {
  display: none;
}

.multi_step_form #msform fieldset .product_select:before {
  content: "";
  position: absolute;
  top: 0;
  right: 20px;
  font: normal normal normal 24px/48px Ionicons;
  color: #5f6771;
}

.multi_step_form #msform fieldset .product_select .list {
  width: 100%;
}

.multi_step_form #msform fieldset .done_text {
  padding-top: 40px;
}

.multi_step_form #msform fieldset .done_text .don_icon {
  height: 36px;
  width: 36px;
  line-height: 36px;
  font-size: 22px;
  margin-bottom: 10px;
  background: #5cb85c;
  display: inline-block;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
}

.multi_step_form #msform fieldset .done_text h6 {
  line-height: 23px;
}

.multi_step_form #msform fieldset .code_group {
  margin-bottom: 60px;
}

.multi_step_form #msform fieldset .code_group .form-control {
  border: 0;
  border-bottom: 1px solid #a1a7ac;
  border-radius: 0;
  display: inline-block;
  width: 30px;
  font-size: 30px;
  color: #5f6771;
  padding: 0;
  margin-right: 7px;
  text-align: center;
  line-height: 1;
}

.multi_step_form #msform fieldset .passport {
  margin-top: -10px;
  padding-bottom: 30px;
  position: relative;
}

.multi_step_form #msform fieldset .passport .don_icon {
  height: 36px;
  width: 36px;
  line-height: 36px;
  font-size: 22px;
  position: absolute;
  top: 4px;
  right: 0;
  background: #5cb85c;
  display: inline-block;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
}

.multi_step_form #msform fieldset .passport h4 {
  font: 500 15px/23px "Roboto", sans-serif;
  color: #5f6771;
  padding: 0;
}

.multi_step_form #msform fieldset .input-group {
  padding-bottom: 40px;
}

.multi_step_form #msform fieldset .input-group .custom-file {
  width: 100%;
  height: auto;
}

.multi_step_form #msform fieldset .input-group .custom-file .custom-file-label {
  width: 168px;
  border-radius: 5px;
  cursor: pointer;
  font: 700 14px/40px "Roboto", sans-serif;
  border: 1px solid #99a2a8;
  text-align: center;
  transition: all 300ms linear 0s;
  color: #5f6771;
}

.multi_step_form #msform fieldset .input-group .custom-file .custom-file-label i {
  font-size: 20px;
  padding-right: 10px;
}

.multi_step_form #msform fieldset .input-group .custom-file .custom-file-label:hover,
.multi_step_form #msform fieldset .input-group .custom-file .custom-file-label:focus {
  background: #5cb85c;
  border-color: #5cb85c;
  color: #fff;
}

.multi_step_form #msform fieldset .input-group .custom-file input {
  display: none;
}

.multi_step_form #msform fieldset .file_added {
  text-align: left;
  padding-left: 190px;
  padding-bottom: 60px;
}

.multi_step_form #msform fieldset .file_added li {
  font: 400 15px/28px "Roboto", sans-serif;
  color: #5f6771;
}

.multi_step_form #msform fieldset .file_added li a {
  color: #5cb85c;
  font-weight: 500;
  display: inline-block;
  position: relative;
  padding-left: 15px;
}

.multi_step_form #msform fieldset .file_added li a i {
  font-size: 22px;
  padding-right: 8px;
  position: absolute;
  left: 0;
  transform: rotate(20deg);
}

.multi_step_form #msform #progressbar {
  /*margin-bottom: 30px;*/
  overflow: hidden;
  margin-top: -21px;
  padding: 0;
}

.multi_step_form #msform #progressbar li {
  list-style-type: none;
  color: #99a2a8;
  font-size: 9px;
  width: calc(100% / 12);
  float: left;
  position: relative;
  font: 500 13px/1 "Roboto", sans-serif;
}

.multi_step_form #msform #progressbar li:nth-child(2):before {
  content: "";
}

.multi_step_form #msform #progressbar li:nth-child(3):before {
  content: "";
}

.multi_step_form #msform #progressbar li:nth-child(4):before {
  content: "";
}

.multi_step_form #msform #progressbar li:nth-child(5):before {
  content: "";
}

.multi_step_form #msform #progressbar li:nth-child(6):before {
  content: "";
}

.multi_step_form #msform #progressbar li:nth-child(7):before {
  content: "";
}

.multi_step_form #msform #progressbar li:nth-child(8):before {
  content: "";
}

.multi_step_form #msform #progressbar li:nth-child(9):before {
  content: "";
}

.multi_step_form #msform #progressbar li:nth-child(10):before {
  content: "";
}

.multi_step_form #msform #progressbar li:nth-child(11):before {
  content: "";
}

.multi_step_form #msform #progressbar li:nth-child(12):before {
  content: "";
}

.multi_step_form #msform #progressbar li:before {
  content: "";
  font: normal normal normal 30px/50px Ionicons;
  width: 0px;
  height: 14px;
  line-height: 50px;
  display: block;
  background: #eaf0f4;
  border-radius: 50%;
  margin: 0 auto 10px auto;
}

.multi_step_form #msform #progressbar li:after {
  content: "";
  width: 100%;
  height: 10px;
  background: #eaf0f4;
  position: absolute;
  left: -50%;
  top: 21px;
  z-index: -1;
}

.multi_step_form #msform #progressbar li:last-child:after {
  width: 150%;
}

.multi_step_form #msform #progressbar li.active {
  color: #5cb85c;
}

.multi_step_form #msform #progressbar li.active:before,
.multi_step_form #msform #progressbar li.active:after {
  background: #fe7620;
  color: white;
}

.multi_step_form #msform .buy_progressbar li.active:before,
.multi_step_form #msform .buy_progressbar li.active:after {
  background: #a5db63 !important;
}

.multi_step_form #msform .action-button {
  background: #4d94ac;
  color: white;
  border: 0 none;
  border-radius: 5px;
  cursor: pointer;
  height: 45px;
  min-width: 98px;
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  /*font: 700 14px/40px "Roboto", sans-serif;*/
  /*border: 1px solid #5cb85c;*/
  margin: 25px 5px;
  text-transform: uppercase;
  display: inline-block;
  float: left;
  padding: 12px;
  position: relative;
}

.multi_step_form #msform .action-button:hover,
.multi_step_form #msform .action-button:focus {
  background: #4d94ac;
  border-color: #405867;
}

.multi_step_form #msform .previous_button {
  background: transparent;
  color: #99a2a8;
  border: 1px solid #878787;
  border-color: #99a2a8;
  float: left;
  /*  margin-left: 35px;*/
}

.multi_step_form #msform .previous_button:hover,
.multi_step_form #msform .previous_button:focus {
  background: #405867;
  border-color: #878787;
  color: #fff;
}

.action_button_box {
  padding: 20px 40px;
  float: right;
}

/*-------------------Buy progrssbar-----------*/
.multi_step_form #msform #buy_progressbar {
  /*margin-bottom: 30px;*/
  overflow: hidden;
  margin-top: -21px;
  padding: 0;
}

.multi_step_form #msform #buy_progressbar li {
  list-style-type: none;
  color: #99a2a8;
  font-size: 9px;
  width: calc(100% / 12);
  float: left;
  position: relative;
  font: 500 13px/1 "Roboto", sans-serif;
}

.multi_step_form #msform #buy_progressbar li:nth-child(2):before {
  content: "";
}

.multi_step_form #msform #buy_progressbar li:nth-child(3):before {
  content: "";
}

.multi_step_form #msform #buy_progressbar li:nth-child(4):before {
  content: "";
}

.multi_step_form #msform #buy_progressbar li:nth-child(5):before {
  content: "";
}

.multi_step_form #msform #buy_progressbar li:nth-child(6):before {
  content: "";
}

.multi_step_form #msform #buy_progressbar li:nth-child(7):before {
  content: "";
}

.multi_step_form #msform #buy_progressbar li:nth-child(8):before {
  content: "";
}

.multi_step_form #msform #buy_progressbar li:nth-child(9):before {
  content: "";
}

.multi_step_form #msform #buy_progressbar li:nth-child(10):before {
  content: "";
}

.multi_step_form #msform #buy_progressbar li:nth-child(11):before {
  content: "";
}

.multi_step_form #msform #buy_progressbar li:nth-child(12):before {
  content: "";
}

.multi_step_form #msform #buy_progressbar li:before {
  content: "";
  font: normal normal normal 30px/50px Ionicons;
  width: 0px;
  height: 14px;
  line-height: 50px;
  display: block;
  background: #eaf0f4;
  border-radius: 50%;
  margin: 0 auto 10px auto;
}

.multi_step_form #msform #buy_progressbar li:after {
  content: "";
  width: 100%;
  height: 10px;
  background: #eaf0f4;
  position: absolute;
  left: -50%;
  top: 21px;
  z-index: -1;
}

.multi_step_form #msform #buy_progressbar li:last-child:after {
  width: 150%;
}

.multi_step_form #msform #buy_progressbar li.active {
  color: #5cb85c;
}

.multi_step_form #msform #buy_progressbar li.active:before,
.multi_step_form #msform #buy_progressbar li.active:after {
  background: #a5db63;
  color: white;
}

/*.multi_step_form #msform .action-button {
  background: #4D94AC;
  color: white;
  border: 0 none;
  border-radius: 5px;
  cursor: pointer;
  height: 45px;
  min-width: 98px;
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  font: 700 14px/40px "Roboto", sans-serif;
  border: 1px solid #5cb85c;
  margin: 0 5px;
  text-transform: uppercase;
  display: inline-block;
  float: right;
}
.multi_step_form #msform .action-button:hover, .multi_step_form #msform .action-button:focus {
  background: #4D94AC;
  border-color: #405867;
}
.multi_step_form #msform .previous_button {
  background: transparent;
  color: #99a2a8;
  border: 1px solid #878787;
  border-color: #99a2a8;
  float: right;
  margin-left: 35px;
}
.multi_step_form #msform .previous_button:hover, .multi_step_form #msform .previous_button:focus {
  background: #405867;
  border-color: #878787;
  color: #fff;
}*/

#buy-button:checked+.button-label {
  background: #29a6d4;
  color: #29a6d4;
  text-align: center;
}

#buy-button:checked+.button-label:hover {
  background: #29a6d4;
  color: #efefef;
}

#buy-lease1-button:checked+.button-label {
  background: #29a6d4;
  color: #efefef;
  text-align: center;
}

#buy-lease1-button:checked+.button-label:hover {
  background: #29a6d4;
  color: #efefef;
}

#buy-lease2-button:checked+.button-label {
  background: #29a6d4;
  color: #efefef;
  text-align: center;
}

#buy-lease2-button:checked+.button-label:hover {
  background: #29a6d4;
  color: #efefef;
}

#buy-lease3-button:checked+.button-label {
  background: #29a6d4;
  color: #efefef;
  text-align: center;
}

#buy-lease3-button:checked+.button-label:hover {
  background: #29a6d4;
  color: #efefef;
}

#buy-lease4-button:checked+.button-label {
  background: #29a6d4;
  color: #efefef;
  text-align: center;
}

#buy-lease4-button:checked+.button-label:hover {
  background: #29a6d4;
  color: #efefef;
}

#fishing-button:checked+.button-label {
  background: #29a6d4;
  color: #efefef;
  text-align: center;
}

#fishing-button:checked+.button-label:hover {
  background: #29a6d4;
  color: #efefef;
}

#craft-button:checked+.button-label {
  background: #29a6d4;
  color: #efefef;
  text-align: center;
}

#craft-button:checked+.button-label:hover {
  background: #29a6d4;
  color: #efefef;
}

#other-button:checked+.button-label {
  background: #29a6d4;
  color: #efefef;
  text-align: center;
}

#other-button:checked+.button-label:hover {
  background: #29a6d4;
  color: #efefef;
}

/*-----------------sell screen 17--------------*/
.example_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  color: #29a6d4;
}

.address_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  color: #000000;
  text-align: center;
  /*  margin-bottom: 33px;*/
}

.address_text span {
  color: #878787;
}

/*-----------------sell screen 18--------------*/
.combo-select-dropdown input,
.combo-select-dropdown li {
  padding: 0.6rem 1rem;
  margin: 0;
}

.combo-select-dropdown li {
  position: relative;
  font-size: 12px;
}

.combo-select-dropdown ul {
  list-style: none;
  padding: 0;
}

.combo-select-dropdown li li:hover {
  color: white;
  background-color: #dff8ff;
  color: #29a6d4;
}

.combo-select-dropdown li li.current {
  color: white;
  background-color: pink;
}

.combo-select-dropdown li li.active {
  color: white;
  background-color: skyblue;
  border: 1px solid #29a6d4;
}

.select-list-group {
  position: relative;
}

.select-list-group,
.select-list-group * {
  width: 100%;
}

.select-list-group .select-list-group-search+.select-list-group__toggle:after {
  content: "v";
  font-family: sans-serif;
  position: absolute;
  top: 0.6rem;
  right: 0.7rem;
  width: 2rem;
  padding: 0.6rem;
  text-align: center;
}

.select-list-group .select-list-group-search:focus+.select-list-group__toggle:after {
  content: "^";
  border: 1px solid #29a6d4;
}

.select-list-group [data-toggle="false"] {
  display: none;
}

.select-list-group [data-toggle="true"] {
  display: inherit;
  box-shadow: 0 3px 7px -2px rgba(0, 0, 0, 0.2);
}

.select-list-group li[data-display="false"] {
  display: none;
}

.select-list-group li[data-display="true"] {
  display: inherit;
}

.select-list-group li[data-highlight="false"] {
  border-left: 0;
}

.select-list-group li[data-highlight="true"] {
  color: white;
  background-color: grey;
}

.select-list-group::after {
  position: absolute;
  right: 0;
  margin-right: -5px;
  top: 25px;
  right: 35px;
  content: "";
  width: 10px;
  height: 10px;
  background: url(https://image.flaticon.com/icons/png/512/25/25756.png);
  background-repeat: no-repeat;
  background-size: 10px 10px;
}

.select-list-group-search {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  border: 1px solid #878787;
  border-radius: 4px;
}

.select-list-group-search:focus {
  background: #eefbff !important;
  border: 1px solid #29a6d4 !important;
  outline: none;
}

.select-list-group-search[type="text"]:focus {
  border: 3px solid #555;
}

.select-list-group-search.active {
  background: #eefbff !important;
  border: 1px solid #29a6d4 !important;
}

.select-list-group-search:hover {
  background: #eefbff !important;
  border: 1px solid #29a6d4 !important;
}

.select-list-group-list {
  font-family: "Gotham-Pro-Regular";
  border: 1px solid #878787;
  margin-top: 5px;
  border-radius: 5px;
  background: #f9f9f9;
  color: #878787;
}

.select-list-group-list:focus {
  border: 1px solid #29a6d4 !important;
}

.select-lg-dropdown {
  padding: 0 !important;
  margin-top: 5px !important;
}

/*----------------------------------------------product detail---------------------------------------*/
.lightbox {
  /* Hidden lightbox image */
  display: none;
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
}

.lightbox img {
  max-width: 90%;
  max-height: 80%;
  margin-top: 2%;
}

.lightbox:target {
  /* Show the lightbox */
  display: block;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 3em;
  color: #fff;
  text-decoration: none;
}

.close_popup {
  top: 0 !important;
}

.thumbnail {
  max-width: 180px;
}

.thumbnail-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ................................................ swiper gallery .......................................................... */

.swiper {
  width: 500px;
  min-width: 300px;
  height: auto;
  padding-bottom: 15px;
}

.swiper-slide-img {
  height: auto;
  width: 100%;
  /*border: 30px solid #d8d8d8;*/
  /* move next and prev buttons off img */
  min-height: 353px;
}

.swiper-slide-img:hover {
  cursor: pointer;
}

.gallery-thumbs .swiper-slide img:hover,
.swiper-slide img:hover {
  cursor: pointer;
}

.swiper-pagination {
  background-color: #d8d8d8;
  padding: 10px;
  z-index: 0 !important;
}

.swiper-button-prev {
  position: absolute;
  transform: translateX(-40%);
  color: black !important;
}

.swiper-button-prev:focus {
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.swiper-button-next {
  position: absolute;
  transform: translateX(40%);
  color: black !important;
  font-weight: 900;
}

.swiper-button-next:focus {
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.swiper-pagination {
  background: linear-gradient(to bottom right, #c2c2f0, #8585e0);
  border-radius: 10px;
}

/*active pagination*/
.swiper-pagination .swiper-pagination-bullet-active {
  background-color: rgb(255, 255, 255);
}

/*other pagination*/
.swiper-pagination .swiper-pagination-bullet {
  height: 15px;
  width: 15px;
}

.swiper-pagination .swiper-pagination-bullet:hover {
  transform: scale(1.2);
  transition: all 0.5s;
}

.swiper-pagination .swiper-pagination-bullet:focus {
  outline: none;
  background-color: rgb(255, 255, 255);
  -webkit-tap-highlight-color: rgb(248, 248, 248);
}

.minimum-height {
  min-height: 257px;

  @include small-screen {
    min-height: calc((0.3002 * 100vw) + 21.49px);
  }
}

/* ................................................ thumbs .......................................................... */

.gallery-thumbs {
  padding: 0;
  margin-top: 0;
  margin-bottom: 40px;
}

.gallery-thumbs .swiper-slide {
  width: 25%;
  opacity: 0.8;
}

.gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}

.gallery-thumbs .swiper-slide img {
  width: 100%;
  height: 70px;
  min-height: 40px;
  object-fit: cover;
}

/* ................................................ modal .......................................................... */
/*.modal {
  display:none;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
}*/

/*.modal-content {
  background-color: rgb(0, 0, 0);
  padding: 0;
  height: 100%;
  width: 100%;
  min-width: 300px;
  animation-name: modalOpen;
  animation-duration: 1s;
}*/

.closeBtn {
  position: absolute;
  /* removed from normal flow of doc, positioned relative to its nearest ancestor (modal content)*/
  z-index: 4;
  color: white;
  right: 5px;
  font-size: 30px;
}

.closeBtn:hover,
.closeBtn:focus {
  color: rgb(114, 114, 114);
  text-decoration: none;
  cursor: pointer;
}

@keyframes modalOpen {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* ............................................. modal swiper gallery ....................................................... */
/* used ids higher specificity to override gallery styles */

#swiper-container-modal {
  z-index: 3;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
  border: 0;
}

#swiper-pagination-modal {
  display: none;
  /* if pagination not included in modal... index.html pagination buttons dnt change (black for active slide)*/
}

#swiper-button-next-modal {
  transform: translateX(-40%);
  color: white;
}

#swiper-button-prev-modal {
  transform: translateX(40%);
  color: white;
}

.swiper-slide-modal {
  height: 100vh;
}

.swiper-lazy-modal {
  object-fit: contain;
  border: 0;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px) !important;
}

/* ............................................. responsiveness ....................................................... */
@media all and (max-width: 520px) {
  .swiper {
    width: calc(100vw - 20px);
  }

  .swiper-button-next:after {
    font-size: 20px !important;
    transform: translateX(60%);
  }

  .swiper-button-prev:after {
    font-size: 20px !important;
    transform: translateX(-60%);
  }

  #swiper-button-next-modal {
    font-size: 20px !important;
    transform: translateX(20%);
  }

  #swiper-button-prev-modal {
    font-size: 20px !important;
    transform: translateX(-20%);
  }

  .swiper-pagination {
    padding: 4px;
  }

  .swiper-slide-img {
    width: calc(100vw-32px);
    height: auto;
    border: 16px solid #d8d8d8;
    /* move next and prev buttons off img */
    min-height: calc((0.5338 * 100vw) + 48.476px);
  }

  .swiper-pagination .swiper-pagination-bullet {
    height: 12px;
    width: 12px;
  }

  .gallery-thumbs .swiper-slide img {
    height: 14vw;
  }
}

@media all and (max-width: 360px) {
  .swiper-slide-img {
    border: 1px solid #d8d8d8;
  }
}

.item {
  width: 100%;
  overflow: hidden;
  background: #000;
}

.fancybox-container {
  width: 67% !important;
}

.fancybox-content {
  transform: translate(195px, 194px) scale(1, 1);
}

.fancybox-image {}

button.gm-control-active {
  top: 45px !important;
  left: 0 !important;
}

/*mobile menu Dashboard*/

/* our code start */
.mobile {
  position: relative;
  /*padding: 10px;*/
  background: #333333;
  text-align: center;
  width: max-content;
  float: left;
}

#button {
  display: none;
}

.mobile label {
  width: 60px;
  overflow: hidden;
  display: inline-block;
  background: #666;
  height: 50px;
  padding-top: 5px;
  cursor: pointer;
}

.mobile label span {
  width: 50px;
  height: 10px;
  display: block;
  margin: 0 auto;
  margin-bottom: 5px;
  background: #ffffff;
}

.mobile ul {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) scaleY(0.5);
  transform-origin: top center;
  text-align: left;
  white-space: no-wrap;
  background: #000;
  padding: 10px 0 15px;
  border: 1px solid #333;
  visibility: hidden;
  opacity: 0;

  transition: all 0.3s ease;
  z-index: 1;
}

#button:checked~ul {
  visibility: visible;
  opacity: 1;
  transform: translateX(-50%) scaleY(1);
}

.mobile ul a {
  color: #fff;
  display: block;
  padding: 10px 30px;
  font-size: 20px;
  text-transform: capitalize;
  transition: all 0.5s ease;
}

.mobile ul a:hover {
  background: #fff;
  color: #333;
}

/* our code end */
/*-----------------------------------------------sell 21---------------------------------------------------------*/
form {
  /* padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;*/
}

.input1 {
  margin: 1.2em auto;
  position: relative;
  display: flex;
}

.input1 span {
  font-family: "Gotham-Pro-Regular";
  font-size: 12px;
  content: "";
  position: absolute;
  left: 0.8em;
  top: -2.5em;
  align-self: center;
  transition: all 200ms ease;
  transform: translateY(1.3em);
  user-select: none;
  pointer-events: none;
  color: #878787;
  z-index: 0;
  padding: 2px 5px;
}

.input1 span::before {
  content: "";
  position: absolute;
  background-color: white;
  width: calc(100% + 10px);
  left: -5px;
  /*bottom: .27em;*/
  height: 100%;
  z-index: -1;
}

.input1:focus-within input {
  border: 2px solid #878787;
}

.input1:focus-within span,
.stay-put {
  /*transform: translateY(0);
  color: slateblue;
  font-size: 0.8em;*/
}

#input1 {
  font-family: "Gotham-Pro-Regular";
  font-size: 12px;
  border: 1px solid #878787;
  border-radius: 5px;
  padding: 9px 15px;
  color: #878787;
  width: 100%;
  height: 42px;
}

.measurements {
  display: inline-flex;
  gap: 10px;
  width: 100%;
  align-items: center;
}

.select-dropdown,
.select-dropdown * {
  font-family: "Gotham-Pro-Regular";
  font-size: 12px;
  background: #f3f3f3;
  color: #878787;
  /*  margin: 0;*/
  padding: 0;
  position: relative;
  box-sizing: border-box;
}

.select-dropdown {
  position: relative;
  /*background-color: #F3F3F3;*/
  border-radius: 4px;
  margin: 1.5em auto;
}

.select-dropdown select {
  font-size: 1rem;
  font-weight: normal;
  max-width: 100%;
  padding: 8px 24px 8px 10px;
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.select-dropdown select:active,
.select-dropdown select:focus {
  outline: none;
  box-shadow: none;
}

.select-dropdown:after {
  /* content: "";
  position: absolute;
  top: 50%;
  right: 8px;
  width: 0;
  height: 0;
  margin-top: -2px;
  border-top: 5px solid #aaa;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;*/
}

.nice-select {
  background: #f3f3f3;
  border: 1px solid #878787 !important;
}

/**/
/*Styling Selectbox*/
.dropdown {
  /*  width: 300px;*/
  display: inline-block;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 0 2px rgb(204, 204, 204);
  transition: all 0.5s ease;
  position: relative;
  font-size: 14px;
  color: #474747;
  /*  height: 100%;*/
  text-align: left;
}

.dropdown .select {
  cursor: pointer;
  display: block;
  padding: 12px;
}

.dropdown .select>i {
  font-size: 13px;
  color: #888;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  float: right;
  line-height: 20px;
  transform: rotate(270deg);
}

.dropdown:hover {
  box-shadow: 0 0 4px rgb(204, 204, 204);
}

.dropdown:active {
  background-color: #f8f8f8;
}

.dropdown.active:hover,
.dropdown.active {
  box-shadow: 0 0 4px rgb(204, 204, 204);
  border-radius: 2px 2px 0 0;
  background-color: #f8f8f8;
}

.dropdown.active .select>i {
  transform: rotate(-90deg);
}

.dropdown .dropdown-menu {
  position: absolute;
  background-color: #fff;
  width: 100%;
  left: 0;
  margin-top: 1px;
  box-shadow: 0 1px 2px rgb(204, 204, 204);
  border-radius: 0 1px 2px 2px;
  overflow: hidden;
  display: none;
  max-height: 144px;
  overflow-y: auto;
  z-index: 9;
  min-width: 0;
}

.dropdown .dropdown-menu li {
  padding: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.dropdown .dropdown-menu {
  padding: 0;
  list-style: none;
}

.dropdown .dropdown-menu li:hover {
  background-color: #f2f2f2;
}

.dropdown .dropdown-menu li:active {
  background-color: #e2e2e2;
}

/*----------------------------------------------------------sell 22----------------------------------------*/
.dropdown_2 {
  position: relative;
  /*    top: 50px;*/
  /*    left: 45%;*/
  margin-top: 5px;
  width: 100%;
  height: 50px;
  margin-bottom: 15px;
}

.dropdown_2::before {
  content: "";
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 1000;
  width: 12px;
  height: 12px;
  border: 2px solid #878787;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(-45deg);
  transition: 0.5s;
  pointer-events: none;
}

.dropdown_2.active::before {
  top: 22px;
  transform: rotate(-225deg);
}

.dropdown_2 input {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: #fff;
  /*    font-size: 1.2em;*/
  border: none;
  outline: none;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  padding: 12px 20px;
  border-radius: 5px;
  border: 1px solid #878787;
}

.dropdown_2 .options {
  position: absolute;
  top: 50px;
  width: 100%;
  background: #fff;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  overflow: hidden;
  display: none;
  z-index: 6;
}

.dropdown_2.active .options {
  display: block;
}

.dropdown_2 .options div {
  padding: 12px 20px;
  cursor: pointer;
}

.dropdown_2 .options div:hover {
  background: #62baea;
  color: #fff;
}

.text-box_2 {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  width: 100%;
  padding: 12px 20px;
  border-radius: 10px;
  border: 1px solid #878787;
  margin-top: 5px;
  margin-bottom: 15px;
}

/*--------------------------------------------sell 23--------------------------------------------*/
.button-label_2:before {
  height: 0;
  display: none !important;
}

.button-label_2:after {
  display: none !important;
}

.button-label_2 {
  font-family: "Gotham-Pro-Regular";
  font-size: 13px;
  display: inline-block;
  padding: 10px 15px;
  /* margin: 0.5em; */
  cursor: pointer;
  color: #29a6d4;
  border-radius: 0.25em;
  background: #eefbff;
  /* box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2), inset 0 -3px 0 rgba(0, 0, 0, 0.22); */
  transition: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 42px;
  width: 126px;
  text-align: center;
  border: 1px solid #29a6d4;
  margin: 5px;
}

.button-label_2 h1 {
  font-size: 1em;
  line-height: inherit;
  margin: 0;
  color: #4d94ac;
  /*font-family: "Lato", sans-serif;*/
}

.button-label_2:hover {
  background: #eefbff;
  color: #29a6d4;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2), inset 0 -3px 0 rgba(0, 0, 0, 0.32);
}

.button-label_2:active {
  transform: translateY(2px);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2),
    inset 0px -1px 0 rgba(0, 0, 0, 0.22);
}

@media (max-width: 40em) {
  .button-label_2 {
    /*    padding: 0em 1em 3px;*/
    margin: 0.25em;
  }
}

#excellent-button:checked+.button-label_2 {
  background: #29a6d4;
  color: #efefef;
  text-align: center;
}

#excellent-button:checked+.button-label_2:hover {
  background: #29a6d4;
  color: #efefef;
}

#good-button:checked+.button-label_2 {
  background: #29a6d4;
  color: #efefef;
  text-align: center;
}

#good-button:checked+.button-label_2:hover {
  background: #29a6d4;
  color: #efefef;
}

#average-button:checked+.button-label_2 {
  background: #29a6d4;
  color: #efefef;
  text-align: center;
}

#average-button:checked+.button-label_2:hover {
  background: #29a6d4;
  color: #efefef;
}

#poor-button:checked+.button-label_2 {
  background: #29a6d4;
  color: #efefef;
  text-align: center;
}

#poor-button:checked+.button-label_2:hover {
  background: #29a6d4;
  color: #efefef;
}

#morning-button:checked+.button-label_4 {
  background: #29a6d4;
  color: #efefef;
  text-align: center;
}

#morning-button:checked+.button-label_4:hover {
  background: #29a6d4;
  color: #efefef;
}

#mid-day-button:checked+.button-label_4 {
  background: #29a6d4;
  color: #efefef;
  text-align: center;
}

#mid-day-button:checked+.button-label_4:hover {
  background: #29a6d4;
  color: #efefef;
}

#evening-button:checked+.button-label_4 {
  background: #29a6d4;
  color: #efefef;
  text-align: center;
}

#evening-button:checked+.button-label_4:hover {
  background: #29a6d4;
  color: #efefef;
}

#yes2-button:checked+.button-label_2 {
  background: #29a6d4;
  color: #efefef;
  text-align: center;
}

#yes2-button:checked+.button-label_2:hover {
  background: #29a6d4;
  color: #efefef;
}

#no2-button:checked+.button-label_2 {
  background: #29a6d4;
  color: #efefef;
  text-align: center;
}

#no2-button:checked+.button-label_2:hover {
  background: #29a6d4;
  color: #efefef;
}

#plan_buy1-button:checked+.button-label {
  background: #29a6d4;
  color: #efefef;
  text-align: center;
}

#plan_buy1-button:checked+.button-label:hover {
  background: #29a6d4;
  color: #efefef;
}

#plan_buy2-button:checked+.button-label {
  background: #29a6d4;
  color: #efefef;
  text-align: center;
}

#plan_buy2-button:checked+.button-label:hover {
  background: #29a6d4;
  color: #efefef;
}

#plan_buy3-button:checked+.button-label {
  background: #29a6d4;
  color: #efefef;
  text-align: center;
}

#plan_buy3-button:checked+.button-label:hover {
  background: #29a6d4;
  color: #efefef;
}

#plan_buy4-button:checked+.button-label {
  background: #29a6d4;
  color: #efefef;
  text-align: center;
}

#plan_buy4-button:checked+.button-label:hover {
  background: #29a6d4;
  color: #efefef;
}

/*mobile view */

/* HEADER
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.menu {
  position: relative;
  /*  padding: 1.5rem 3rem;*/
  background: var(--purple-1);
  float: left;
  width: auto;
}

.menu nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: max-content;
}

.menu .horizontal-logo,
.menu .search span {
  display: none;
}

.menu .vertical-logo {
  max-width: 9.5rem;
}

.menu .top-menu-wrapper {
  color: var(--black);
}

.menu .top-menu-wrapper::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  transition: background 0.5s;
}

.menu .search {
  color: var(--white);
}

.menu .panel,
.menu .top-menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}

.menu .panel1 {
  width: 100%;
  background: var(--purple-1);
  transition-delay: 0.3s;
}

.menu .panel2 {
  width: calc(100% - 3rem);
  background: var(--red);
  transition-delay: 0.1s;
}

.menu .top-menu {
  /*  display: flex;*/
  flex-direction: column;
  width: calc(100% - 0rem);
  overflow-y: auto;
  /*  padding: 2rem;*/
  background: var(--white);
  height: 100%;
}

.menu .top-menu-wrapper.show-offcanvas::before {
  background: var(--overlay);
  z-index: 1;
}

.menu .top-menu-wrapper.show-offcanvas .panel,
.menu .top-menu-wrapper.show-offcanvas .top-menu {
  transform: translate3d(0, 0, 0);
  transition-duration: 0.7s;
}

.menu .top-menu-wrapper.show-offcanvas .panel1 {
  transition-delay: 0s;
}

.menu .top-menu-wrapper.show-offcanvas .panel2 {
  transition-delay: 0.2s;
}

.menu .top-menu-wrapper.show-offcanvas .top-menu {
  transition-delay: 0.4s;
  /*  box-shadow: rgba(0, 0, 0, 0.25) 0 0 4rem 0.5rem;*/
  top: 156px;
  z-index: 1200;
  height: initial;
  margin-bottom: 0;
}

.close-mobile-menu {
  position: initial;
  float: right;
  border: none;
  background: none;
}

/* FORM
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.menu .search-form {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  padding: 1rem 0;
  background: var(--purple-2);
  transition: all 0.2s;
}

.menu .search-form.is-visible {
  visibility: visible;
  opacity: 1;
}

.menu .search-form div {
  position: relative;
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
}

.menu .search-form input {
  width: 100%;
  font-size: 2rem;
  height: 4rem;
  padding: 0 2rem;
}

.menu .search-form button {
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--purple-1);
}

/* TOP MENU
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.menu .top-menu li+li {
  margin-top: 1.5rem;
}

.menu .top-menu>li:last-child {
  margin-top: auto;
}

.menu ul a {
  display: inline-block;
  font-size: 1.3rem;
  text-transform: uppercase;
  transition: color 0.35s ease-out;
}

.menu ul a:hover {
  color: var(--red);
}

.menu .has-dropdown i {
  display: none;
}

.menu .sub-menu {
  padding: 0rem 0rem 0;
}

.menu .top-menu .mob-block {
  /*  display: flex;*/
  align-items: center;
  justify-content: space-between;
  /*  margin-bottom: 3rem;*/
  list-style: none;
}

.menu .top-menu .mob-block i {
  color: var(--lightgray);
}

.menu .socials {
  display: flex;
  margin-top: 3rem;
  /* some browsers ignore the padding-bottom: 2rem of the .top-menu because it has overflow-y:auto
  https://bugzilla.mozilla.org/show_bug.cgi?id=748518
  so we add a bottom margin to the .socials
  */
  margin-bottom: 1rem;
}

.menu .socials li+li {
  margin-top: 0;
}

.menu .socials .fa-stack {
  font-size: 1.7rem;
}

.menu .socials .fab {
  font-size: 1.2rem;
}

/* MQ
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media screen and (min-width: 550px) {
  .menu .panel1 {
    width: 60%;
  }

  .menu .panel2 {
    width: calc(60% - 3rem);
  }

  .menu .top-menu {
    /*    width: calc(60% - 6rem);*/
  }
}

@media screen and (min-width: 768px) {
  .menu .top-menu {
    /*    padding: 4rem;*/
  }

  .menu ul a {
    font-size: 1.6rem;
  }

  .menu .search-form input {
    font-size: 2.4rem;
    height: 5rem;
    line-height: 5rem;
  }
}

.open-mobile-menu {
  border: 1px solid #034a63;
  background: none;
  border-radius: 4px;
  padding: 6px;
  background: #eefaff;
  margin-right: 5px;
  cursor: pointer;
}

@media screen and (min-width: 995px) {
  .menu {
    padding: 0 3rem;
  }

  .menu .panel,
  .menu .open-mobile-menu,
  .menu .vertical-logo,
  .menu .top-menu .mob-block,
  .menu .top-menu>li:last-child,
  .menu .top-menu-wrapper::before {
    display: none;
  }

  .open-mobile-menu {
    border: 1px solid #034a63;
    background: none;
  }

  .menu .horizontal-logo {
    display: block;
  }

  .menu .top-menu-wrapper {
    display: flex;
    align-items: center;
    color: var(--white);
  }

  .menu .top-menu {
    flex-direction: row;
    position: static;
    width: auto;
    background: transparent;
    transform: none;
    padding: 0;
    overflow-y: visible;
    box-shadow: none !important;
  }

  .menu .top-menu li+li {
    margin-top: 0;
  }

  .menu .top-menu>li:not(:nth-last-child(2)) {
    margin-right: 3rem;
  }

  .menu .top-menu>li>a {
    padding: 3rem 0.5rem;
  }

  .menu ul a {
    font-size: 1.3rem;
  }

  .menu .has-dropdown i {
    display: inline-block;
  }

  .menu .sub-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 1.5rem 2rem;
    background: var(--purple-2);
  }

  .menu .sub-menu li+li {
    margin-top: 1.2rem;
  }

  .menu .sub-menu a {
    padding: 0.5rem 1rem;
    white-space: nowrap;
  }

  .menu .has-dropdown {
    position: relative;
  }

  .menu .has-dropdown:hover .sub-menu {
    display: block;
  }

  .menu .search {
    display: flex;
    align-items: center;
    margin-left: 3rem;
  }
}

@media screen and (min-width: 1200px) {
  .menu .search {
    margin-left: 5rem;
  }

  .menu .search i {
    margin-right: 1.5rem;
  }

  .menu .search span {
    display: block;
    font-weight: 500;
    font-size: 1.6rem;
  }
}

/*-----------------------------------------------sell */

.upload__box {
  /*  border: 1px dotted #707070;*/
  /*  height: 214px;*/
  /*  text-align: center;*/
  /*  padding: 38px;*/
  /*padding: 40px;*/
  /*  background: #F9F9F9;*/
  /*  border-radius: 8px;*/
}

.upload__inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.upload__btn {
  font-family: "Gotham-Pro-Regular";
  display: inline-block;
  font-weight: 600;
  color: #fff;
  text-align: center;
  min-width: 116px;
  /*  padding: 5px;*/
  transition: all 0.3s ease;
  cursor: pointer;
  border: 2px solid;
  background-color: #29a6d4;
  border-color: #29a6d4;
  /*  border-radius: 10px;*/
  /*  line-height: 26px;*/
  font-size: 9px;
  height: 30px;
}

.upload__btn p {
  margin: 6px;
}

.upload__btn:hover {
  background-color: unset;
  color: #4045ba;
  transition: all 0.3s ease;
}

.upload__btn-box {
  border: 1px dashed #707070;
  height: 214px;
  text-align: center;
  margin-bottom: 10px;
  padding: 40px;
  background: #f9f9f9;
  border-radius: 8px;
}

.upload__img-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 50px -10px;
}

.upload__img-box {
  width: 115px;
  padding: 0 10px;
  margin-bottom: 12px;
}

.upload__img-close {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #000000;
  position: absolute;
  top: -10px;
  right: -10px;
  text-align: center;
  line-height: 24px;
  z-index: 1;
  cursor: pointer;
}

.upload__img-close:after {
  content: "✖";
  font-size: 14px;
  color: white;
}

.img-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  padding-bottom: 100%;
  border-radius: 8px;
}

.click_here_to_upload_images_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  color: #bcbcbc;
}

.skip_for_now_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 20px;
  color: #29a6d4;
  text-align: right;
}

#fsbo-button:checked+.button-label {
  background: #29a6d4;
  color: #efefef;
  text-align: center;
}

#fsbo-button:checked+.button-label:hover {
  background: #29a6d4;
  color: #efefef;
}

#fsbb-button:checked+.button-label {
  background: #29a6d4;
  color: #efefef;
}

#fsbb-button:checked+.button-label:hover {
  background: #29a6d4;
  color: #efefef;
}

.finish_btn {
  background: #fe7620 !important;
  padding: 12px;
  text-align: center;
}

.buy_finish_btn {
  background: #a5db63 !important;
  padding: 12px;
  text-align: center;
}

.submitted_details {
  font-family: "Gotham-Pro-Medium";
  font-size: 18px;
  /*  width: 100%;*/
  margin: auto;
}

.official_number_text {
  font-family: "Gotham-Pro-Medium";
  font-size: 12px;
  color: #000000;
  padding: 9px;
}

.right_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 12px;
  color: #878787;
  padding: 9px;
}

.your_data_has_been_text {
  font-family: "Gotham-Pro-Medium";
  font-size: 12px;
  color: #034a63;
  text-align: center;
  margin-bottom: 0;
}

.your_data_has_been_text span {
  color: #fe7620;
}

.buy_your_data_has_been_text {
  font-family: "Gotham-Pro-Medium";
  font-size: 18px;
  color: #034a63;
  text-align: center;
  margin-bottom: 0;
}

.buy_your_data_has_been_text span {
  color: #a5db63;
}

.following_text {
  font-family: "Gotham-Pro-Medium";
  font-size: 12px;
  color: #878787;
  text-align: center;
}

.submitted_icon {
  height: 70px;
  text-align: center;
  margin-bottom: 10px;
}

/*--------------------------------------------------------------------------------------------------------*/
.clear-fix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.button-group {
  margin-bottom: 1rem;
  font-size: 0;
  border: 1px solid #ffa500;
  padding: 1rem;
}

.button-group .buy_button {
  margin: 0;
  margin-bottom: 1px;
  margin-right: 12px;
}

.button-group .buy_button:last-child {
  margin-right: 0;
}

.button-group.button-group--expanded .buy_button:first-child:nth-last-child(2),
.button-group.button-group--expanded .buy_button:first-child:nth-last-child(2):first-child:nth-last-child(2)~.buy_button {
  display: inline-block;
  width: calc(50% - 6px);
}

.button-group.button-group--expanded .buy_button:first-child:nth-last-child(3),
.button-group.button-group--expanded .buy_button:first-child:nth-last-child(3):first-child:nth-last-child(3)~.buy_button {
  display: inline-block;
  width: calc(33.33333333333333% - 8px);
}

.button-group.button-group--expanded .buy_button:first-child:nth-last-child(4),
.button-group.button-group--expanded .buy_button:first-child:nth-last-child(4):first-child:nth-last-child(4)~.buy_button {
  display: inline-block;
  width: calc(25% - 9px);
}

.button-group.button-group--expanded .buy_button:first-child:nth-last-child(5),
.button-group.button-group--expanded .buy_button:first-child:nth-last-child(5):first-child:nth-last-child(5)~.buy_button {
  display: inline-block;
  width: calc(20% - 9.6px);
}

.button-group.button-group--expanded .buy_button:first-child:nth-last-child(6),
.button-group.button-group--expanded .buy_button:first-child:nth-last-child(6):first-child:nth-last-child(6)~.buy_button {
  display: inline-block;
  width: calc(16.666666666666664% - 10px);
}

.buy_button {
  background-color: #027faa;
  border: none;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  height: 2rem;
  line-height: 2rem;
  padding: 0 2rem;
  text-transform: uppercase;
  outline: none;
}

/*.buy_button.button--primary {
  background-color: #17aa17;
}
.buy_button.button--warn {
  background-color: #b61b1b;
}
.buy_button:hover,
.buy_button.button--primary:hover,
.buy_button.button-warn:hover {
  background-color: #f60;
}
.buy_button:focus,
.buy_button.button--primary:focus,
.buy_button.button--warn:focus {
  background-color: #f60;
}
.buy_button:active,
.buy_button.button--primary:active,
.buy_button.button--warn:active {
  background-color: #dd5800;
}*/

.buy_button.button__outline {
  background-color: transparent;
  color: #027faa;
  border: 1px solid #027faa;
}

.buy_button.button__outline.buy_button.button__outline--primary {
  border-color: #17aa17;
  color: #17aa17;
}

.buy_button.button__outline.buy_button.button__outline--warn {
  border-color: #b61b1b;
  color: #b61b1b;
}

.buy_button.button__outline:hover,
.buy_button.button__outline.buy_button.button__outline--primary:hover,
.buy_button.button__outline.buy_button.button__outline--warn:hover {
  background-color: #f60;
  border-color: #f60;
  color: #fff;
}

.buy_button.button__outline:focus,
.buy_button.button__outline.buy_button.button__outline--primary:focus,
.buy_button.button__outline.buy_button.button__outline--warn:focus {
  background-color: #f60;
  border-color: #f60;
  color: #fff;
}

.buy_button.button__outline:active,
.buy_button.button__outline.buy_button.button__outline--primary:active,
.buy_button.button__outline.buy_button.button__outline--warn:active {
  background-color: #dd5800;
  border-color: #dd5800;
  color: #fff;
}

/*---------------------------------------------------------------------------------------------------------*/
.select-list-group_2 {
  position: relative;
}

.select-list-group_2,
.select-list-group_2 * {
  width: 100%;
}

.select-list-group_2 .select-list-group-search+.select-list-group__toggle:after {
  content: "v";
  font-family: sans-serif;
  position: absolute;
  top: 0.6rem;
  right: 0.7rem;
  width: 2rem;
  padding: 0.6rem;
  text-align: center;
}

.select-list-group_2 .select-list-group-search:focus+.select-list-group__toggle:after {
  content: "^";
  border: 1px solid #29a6d4;
}

.select-list-group_2 [data-toggle="false"] {
  display: none;
}

.select-list-group_2 [data-toggle="true"] {
  display: inherit;
  box-shadow: 0 3px 7px -2px rgba(0, 0, 0, 0.2);
}

.select-list-group_2 li[data-display="false"] {
  display: none;
}

.select-list-group_2 li[data-display="true"] {
  display: inherit;
}

.select-list-group_2 li[data-highlight="false"] {
  border-left: 0;
}

.select-list-group_2 li[data-highlight="true"] {
  color: white;
  background-color: grey;
}

.select-list-group_2::after {
  position: absolute;
  right: 0;
  margin-right: -5px;
  top: 25px;
  right: 35px;
  content: "";
  width: 10px;
  height: 10px;
  background: url(https://image.flaticon.com/icons/png/512/25/25756.png);
  background-repeat: no-repeat;
  background-size: 10px 10px;
}

.select-list-group-search {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  border: 1px solid #878787;
  border-radius: 4px;
}

.select-list-group-search:focus {
  background: #eefbff !important;
  border: 1px solid #29a6d4 !important;
  outline: none;
}

.select-list-group-search[type="text"]:focus {
  border: 3px solid #555;
}

.select-list-group-search.active {
  background: #eefbff !important;
  border: 1px solid #29a6d4 !important;
}

.select-list-group-search:hover {
  background: #eefbff !important;
  border: 1px solid #29a6d4 !important;
}

.select-list-group-list_2 {
  font-family: "Gotham-Pro-Regular";
  border: 1px solid #878787;
  margin-top: 5px;
  border-radius: 5px;
  background: #f9f9f9;
  color: #878787;
}

.select-list-group-list_2:focus {
  border: 1px solid #29a6d4 !important;
}

.textarea {
  font-family: "Gotham-Pro-Regular";
  font-size: 10px;
  width: 100%;
  height: 170px;
  padding: 10px;
  border: 1px solid #878787;
  border-radius: 5px;
  background: #fbfbfb;
}

/*------------------------------------ Tell us about yourself page start------------------------------------------------*/

#map-canvas {
  height: 385px !important;
  margin: 0px;
  padding: 0px;
}

.controls {
  margin-top: 16px;
  border: 1px solid transparent;
  border-radius: 2px 0 0 2px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  height: 32px;
  outline: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.tell_us_about_yourself_title {
  font-family: "Gotham-Pro-regular";
  font-size: 28px;
  font-weight: 600;
  color: #034a63;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
}

.form_label_text {
  font-family: "Gotham-Pro-regular";
  font-size: 14px;
  color: #434343;
  font-weight: 600;
}

.account-icon {
  display: flex;
  align-items: center;
}

.tell_us_about_yourself_form {
  background: #ffffff;
  border-radius: 9px;
}

.jumbotron {
  background: #ffffff;
  padding: 18px;
  margin-bottom: 0;
  border-radius: 18px;
}

.form-group .form-control {
  font-family: "Gotham-Pro-regular";
  font-size: 14px;
  border: 1px solid #878787;
  background: #fbfbfb;
  height: 42px;
}

.form-group {
  padding: 0px 6px;
  width: 100%;
}

.form-group .email:focus {
  background: #d9d9d9;
}

.form-group textarea {
  font-family: "Gotham-Pro-regular";
  font-size: 12px;
  color: #434343;
  border: 1px solid #878787;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  background: #fbfbfb;
}

#pac-input {
  background-color: #fff;
  font-family: "Gotham-Pro-regular";
  font-size: 12px;
  font-weight: 300;
  margin-left: 12px;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 400px;
  left: 0 !important;
  padding: 10px 30px;
  border: none;
  border-radius: 6px;
  margin: 12px;
  z-index: 6;
}

#pac-input:focus {
  border-color: #4d90fe;
}

.pac-container {
  font-family: "Gotham-Pro-regular";
}

#type-selector {
  color: #fff;
  background-color: #4d90fe;
  padding: 5px 11px 0px 11px;
}

#type-selector label {
  font-family: "Gotham-Pro-regular";
  font-size: 13px;
  font-weight: 300;
}

.location_icon {
  position: absolute;
  /* left: 35px; */
  top: 20px;
  z-index: 6;
}

.gmnoprint {
  bottom: 0;
}

.ct_postion_filter_30 {
  position: absolute;
  top: -45px;
  background: #fff;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 210px;
}

.address {
  font-family: "Gotham-Pro-regular";
  font-size: 12px;
  color: #878787;
  text-align: center;
}

.save_changes_btn {
  font-family: "Gotham-Pro-regular";
  font-size: 16px;
  color: #ffffff;
  background: #4d94ac;
  border: 0;
  padding: 14px;
  border-radius: 4px;
  margin-bottom: 20px;
  cursor: pointer;
}

.ct_information_search {
  position: relative;
}

.ct_information_search input {
  height: 43px;
  border: 1px solid #eee;
  border-radius: 2px;
  outline: none;
  margin-bottom: 0px;
}

.ct_information_search img {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

/*---------------------------------------------package page start-------------------------------------------*/
@media screen and (max-width: 767px) {
  .package_section {
    position: relative;
    top: 0px;
    background: #f9f9f9;
    padding: 0px 30px !important;
  }

  .ct_left_sidebar_css_reset {
    width: 296px;
  }
}

.package_section {
  position: relative;
  margin-top: 95px;
  background: #f9f9f9;
  padding: 0px 60px;
  /*display: -webkit-inline-box;*/
}

.buyer_package_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  text-align: center;
}

.pricing-table {
  display: flex;
  flex-direction: column;
  margin: 0 0 32px;
  background-color: #fff;
  border-radius: 18px;
  border: 1px solid #000000;
  overflow: hidden;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  height: 100%;
}

.pricing-table_2 {
  display: flex;
  flex-direction: column;
  margin: 0 0 32px;
  background-color: #fff;
  border-radius: 18px;
  border: 1px solid #4d94ac;
  overflow: hidden;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.pricing-table_3 {
  display: flex;
  flex-direction: column;
  margin: 0 0 32px;
  background-color: #fff;
  border-radius: 18px;
  border: 1px solid #4d94ac;
  overflow: hidden;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.pricing-table_4 {
  display: flex;
  flex-direction: column;
  margin: 0 0 32px;
  background-color: #fff;
  border-radius: 18px;
  border: 1px solid #4d94ac;
  overflow: hidden;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.pricing-table_5 {
  display: flex;
  flex-direction: column;
  margin: 0 0 32px;
  background-color: #fff;
  border-radius: 18px;
  border: 1px solid #4d94ac;
  overflow: hidden;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.pricing-table.offer {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.4);
}

.pricing-table .package-title {
  /*  display: flex;*/
  justify-content: space-between;
  padding: 16px 24px;
  background-color: #000000;
  font-size: 1.125rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}

.pricing-table .package-title_2 {
  /*  display: flex;*/
  justify-content: space-between;
  padding: 16px 24px;
  background-color: #4d94ac;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}

.pricing-table .package-title_3 {
  /*  display: flex;*/
  justify-content: space-between;
  padding: 16px 24px;
  background-color: #5f971b;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}

.pricing-table .package-title_4 {
  /*  display: flex;*/
  justify-content: space-between;
  padding: 16px 24px;
  background-color: #034a63;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}

.pricing-table .package-title_5 {
  /*  display: flex;*/
  justify-content: space-between;
  padding: 16px 24px;
  background-color: #fe7620;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}

.pricing-table .package-title .sale {
  padding: 0 0.5rem;
  border-radius: 3px;
  background-color: #fff;
  font-size: 0.8125rem;
  font-weight: 500;
  color: #263238;
  line-height: 1.7;
}

.pricing-table .package-layout {
  /*  display: flex;*/
  justify-content: center;
  padding: 1rem 1rem 1rem;
  text-align: center;
}

.pricing-table .package-layout .basic-package-currency {
  padding-right: 4px;
  font-size: 22px;
  font-weight: 500;
  color: #5f971b;
  bottom: -7px;
  position: relative;
}

.pricing-table .package-layout .basic-package-value {
  display: flex;
  align-items: flex-end;
  color: #5f971b;
}

.pricing-table .package-layout .basic-package-value .value {
  font-family: "Gotham-Pro-Regular";
  font-size: 40px;
  font-weight: 300;
  line-height: 1;
  color: #5f971b;
}

.pricing-table .package-layout .basic-package-value .period {
  font-family: "Gotham-Pro-Regular";
  padding: 0 0 5px 4px;
  font-size: 15px;
  font-weight: 300;
  color: #29a6d4;
}

.pricing-table .package-layout .package-currency {
  padding-right: 4px;
  font-size: 22px;
  font-weight: 500;
  color: #29a6d4;
  bottom: -7px;
  position: relative;
}

.pricing-table .package-layout .package-value {
  display: flex;
  align-items: flex-end;
  color: #29a6d4;
}

.pricing-table .package-layout .package-value .value {
  font-family: "Gotham-Pro-Regular";
  font-size: 40px;
  font-weight: 300;
  line-height: 1;
  color: #29a6d4;
}

.pricing-table .package-layout .package-value .period {
  font-family: "Gotham-Pro-Regular";
  padding: 0 0 5px 4px;
  font-size: 15px;
  font-weight: 300;
  color: #29a6d4;
}

.pricing-table .terms {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem 2rem;
  font-size: 0.9125rem;
  height: 100%;
}

.pricing-table .terms .term span {
  font-weight: 500;
}

.pricing-table .buy-now {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem auto 2rem;
  padding: 2px 18px;
  min-width: 128px;
  border: none;
  border-radius: 3px;
  background-color: #2196f3;
  font-family: "Gotham-Pro-Regular";
  font-size: 0.875rem;
  font-weight: 500;
  color: #fff;
  line-height: 39px;
  text-transform: uppercase;
  overflow: hidden;
  will-change: box-shadow;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.pricing-table .buy-now:hover,
.pricing-table .buy-now:focus {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.4);
}

.pricing-table .divider {
  display: block;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0px 32px;
}

.basic_package_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 18px;
}

.free_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 35px;
}

.no_additional_fees_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 13px;
}

.get_started_btn {
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  background: #000000;
  color: #ffffff;
  width: auto;
  padding: 10px;
  border-radius: 5px;
  border: 0;
  margin-top: 20px;
  cursor: pointer;
}

.months_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 9px;
  margin-bottom: 0;
}

.currency {
  display: inline-flex;
}

.hst_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 12px;
  color: #878787;
  text-align: center;
  margin-bottom: 0;
  padding: 15px;
}

.get_started_btn_2 {
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  background: #4d94ac;
  color: #ffffff;
  width: auto;
  padding: 10px;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
}

.get_started_btn_3 {
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  background: #5f971b;
  color: #ffffff;
  width: auto;
  padding: 10px;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
}

.get_started_btn_4 {
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  background: #034a63;
  color: #ffffff;
  width: auto;
  padding: 10px;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
}

.get_started_btn_5 {
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  background: #fe7620;
  color: #ffffff;
  width: auto;
  padding: 10px;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
}

/*---------------------------------------------------------------------------------------------------*/
.payment_form {}

.payment_form {
  /*grid-column: 2/3;
  display: grid;
  grid-template-columns: repeat(2, 11rem);
  grid-template-rows: repeat(5, -webkit-min-content);
  grid-template-rows: repeat(5, min-content);
  grid-column-gap: 2.8rem;
  grid-row-gap: 1.2rem;
  color: var(--greyDark-2);*/
  width: 680px;
  margin: auto;
  padding: 25px;
  background: white;
  border-radius: 18px;
}

.payment_form h2 {
  font-size: 2rem;
  grid-column: 1/3;
  line-height: 1;
  margin-bottom: 1.4rem;
  font-family: "Inter", sans-serif;
}

.form__detail {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  padding-bottom: 1.6rem;
  color: var(--greyDark-1);
  width: -webkit-fill-available;
}

.form__name {
  grid-column: 1/3;
  grid-row: 2/3;
  justify-self: stretch;
}

.form__number {
  grid-column: 1/3;
  grid-row: 3/4;
  justify-self: stretch;
}

.form__expiry {
  grid-column: 1/2;
  grid-row: 4/5;
}

.form__cvv {
  grid-column: 2/3;
  grid-row: 4/5;
  justify-self: flex-end;
  width: 100%;
}

.form__cvv input[type="checkbox"] {
  display: none;
}

.form__cvv .info {
  top: -0.4rem;
  left: 2.4rem;
  color: var(--greyDark-1);
}

.form__btn {
  grid-column: 1/3;
  grid-row: 5/6;
  height: 3.8rem;
  border-radius: 0.6rem;
  border: none;
  background: var(--primary);
  color: #ffffff;
  justify-self: stretch;
  font-family: inherit;
  font-size: 1.6rem;
  cursor: pointer;
}

.form__btn:focus {
  outline: none;
}

.form__btn:hover {
  background: var(--primary-dark);
}

.payment_form ion-icon {
  position: absolute;
  font-size: 2rem;
  top: 3.4rem;
  margin-left: 1rem;
  color: var(--primary-light);
  stroke: var(--primary);
}

.payment_form label {
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  pointer-events: none;
  /*  font-size: 1.4rem;*/
  padding: 0 0 1rem 0;
  line-height: 1;
  color: #000000;
}

.payment_form input,
.StripeElement {
  font-family: "Gotham-Pro-Regular";
  font-size: 12px;
  padding: 1rem 1rem 1rem 1rem;
  border: none;
  background: #fbfbfb;
  border-radius: 6px;
  border: 1px solid #878787;
  color: #878787;
}

select.ct_language_select {
  display: flex;
  align-items: center;
  font-size: 16px;
  /* font-weight: bold; */
  padding: 5px 10px;
  border: 1px solid;
  border-radius: 5px;
  color: #034a63 !important;
  background: transparent !important;
  transition: background 0.3s;
  text-transform: uppercase;
  height: 43px;
  margin-bottom: 0px;
  border-color: #034a63;
  outline: none;
}

.ct_pricing_table_parent .ct_price_table_count:nth-child(3) span.ct_year_to_year_bold {
  font-weight: 500;
  background: #fe7620;
  color: #fff;
  padding: 4px 10px;
  border-radius: 100px;
  font-size: 16px;
  line-height: 14px;
}

.ct_pricing_table_parent .ct_price_table_count:nth-child(3) .pricing-table.pricing-table_4 {
  border-color: #2c2826;
  border-width: 3px;
}

.ct_left_sidebar_css_reset {
  background-color: transparent !important;
  /* background-color: #fff !important; */
  box-shadow: none;
  height: auto;
  overflow: unset !important;

}

@media screen and (max-width: 767px) {
  .signup_logo {
    width: 95px;
  }

  .sell_btn {
    margin-top: 0;
  }

  .buy_btn {
    margin-top: 0;
  }

  .payment_form {
    width: 100%;
  }

  .payment_form input {
    width: 100%;
  }

  .form_flex {
    display: flex;
    gap: 20px !important;
  }

  .sell_btn {
    font-family: "Gotham-Pro-Regular";
    font-size: 16px !important;
    color: #ffffff !important;
    height: 34px;
    width: fit-content;
    background: #fe7620 !important;
    border: 1px solid #ffffff !important;
    text-align: center;
    display: block !important;
    margin-left: 10px;
    margin-top: 8px;
    cursor: pointer;
    border-radius: 5px;
    text-transform: uppercase;
  }

  .search_field_header span.material-icons {
    left: 36px;
    padding: 0px;
    transform: translateY(-50%);
    top: 39px;
  }

  .search_field_header .search {
    padding-bottom: 0;
  }

  .buy_btn {
    font-family: "Gotham-Pro-Regular";
    font-size: 16px !important;
    color: #ffffff !important;
    height: 34px;
    width: fit-content;
    background: #a5db63 !important;
    border: 1px solid #ffffff !important;
    text-align: center;
    display: block !important;
    margin-left: 8px;
    margin-top: 8px;
    cursor: pointer;
    border-radius: 5px;
    text-transform: uppercase;
  }

  .page-wrapper .page-content>div {
    padding-inline: 15px;
  }

  .help_btn {
    display: none !important;
  }

  .package_box {
    margin: 0 !important;
  }
}

.payment_form input::-moz-placeholder {
  color: var(--greyLight-1);
  font-size: inherit;
  letter-spacing: 0.12rem;
}

.payment_form input:-ms-input-placeholder {
  color: var(--greyLight-1);
  font-size: inherit;
  letter-spacing: 0.12rem;
}

.payment_form input::placeholder {
  color: var(--greyLight-1);
  font-size: inherit;
  letter-spacing: 0.12rem;
}

.payment_form input:focus {
  outline: none;
}

.payment_form input:hover {
  border: 1px solid #878787;
}

.alert {
  font-family: "Gotham-Pro-regular";
  font-size: 16px;
  color: var(--alert);
  margin-top: 0.6rem;
  display: flex;
  align-items: center;
  opacity: 1;
}

.alert svg {
  margin-right: 0.4rem;
}

.dribbble {
  position: fixed;
  font-size: 36px;
  right: 20px;
  bottom: 20px;
  color: #ea4c89;
}

.sub_total {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.sub_total_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  color: #878787;
}

.price {
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  color: #434343;
}

.total {
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  color: #000000;
}

.paynow_btn {
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  color: #ffffff;
  background: #4d94ac;
  border: none;
  height: 56px;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
}

.form_flex {
  display: flex;
  gap: 35px;
}

.visa_icon {
  position: absolute;
  right: 20px;
  margin-top: -40px;
}

.monthly_package_box {
  background: white;
  height: 170px;
  padding: 30px;
  margin-top: 40px;
  border-radius: 18px;
}

.monthly_package_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 20px;
  color: #434343;
}

.basic_package_monthly_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  color: #000000;
}

.upgrade_plan_btn {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  color: #4d94ac;
  border: 1px solid #4d94ac;
  background: #eefbff;
  border-radius: 4px;
  padding: 7px 15px;
  margin-top: 25px;
  text-transform: uppercase;
  cursor: pointer;
}

.package-currency_5 {
  padding-right: 4px;
  font-size: 22px;
  font-weight: 500;
  color: #000000;
  bottom: -7px;
  position: relative;
}

.package-value_5 {
  display: flex;
  align-items: flex-end;
  color: #000000;
}

.package-value_5 .value {
  font-family: "Gotham-Pro-Regular";
  font-size: 40px;
  font-weight: 300;
  line-height: 1;
  color: #000000;
}

.next_payment_box {
  background: white;
  height: 170px;
  padding: 30px;
  border-radius: 18px;
  margin-top: 40px;
}

.next_payment_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  color: #878787;
}

.next_payment_date {
  font-family: "Gotham-Pro-Regular";
  font-size: 20px;
}

.renewal_btn {
  border: 1px solid #4d94ac;
  background: #eefbff;
  color: #4d94ac;
  text-transform: uppercase;
  padding: 7px 10px;
  border-radius: 4px;
}

/*-------------------------------------------------------------------------------------------------------------------*/
.lising_table {
  margin: auto;
  width: 100%;
}

.lising_table tr th {
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  padding: 10px;
  border: 1px solid #ababab;
  text-align: center;
}

.lising_table tr td {
  font-family: "Gotham-Pro-Regular";
  font-size: 12px;
  padding: 10px;
  border: 1px solid #ababab;
  text-align: center;
}

tr:nth-child(odd) {
  /*  background-color: #FBFBFB;*/
}

.sell_listing_box {
  padding: 0 20px;
}

.action_btn {
  border: none;
  background: none;
}

.action_btn:focus {
  border: none;
  outline: none;
}

/*pagination*/
.pagination {
  display: inline-flex !important;
  align-items: center;
  gap: 12px;
  margin-top: 20px;
  /*  margin-top: 200px;*/
}

/*
.btn {
  border: 1px solid #087f5b;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background: none;
  cursor: pointer;
}

.btn-icon {
  width: 24px;
  height: 24px;
  stroke: #087f5b;
}

.btn:hover {
/*  background-color: #087f5b;*/
/*}*/

.btn:hover .btn-icon {
  stroke: #fff;
}

.page-link:link,
.page-link:visited {
  text-decoration: none;
  color: #343a40;
  font-size: 18px;
  height: 36px;
  width: 36px;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.page-link:hover,
.page-link:active,
.page-link.page-link--current {
  background-color: #4d94ac;
  color: #fff;
}

.dots {
  color: #868e96;
}

/**/
.list-wrapper {
  padding: 15px;
  overflow: hidden;
}

.list-item {
  /*  border: 1px solid #EEE;*/
  background: #fff;
  margin-bottom: 10px;
  padding: 30px;
  /*  box-shadow: 0px 0px 10px 0px #EEE;*/
  border-radius: 18px;
}

.list-item h4 {
  color: #ff7182;
  font-size: 18px;
  margin: 0 0 5px;
}

.list-item p {
  margin: 0;
}

.simple-pagination ul {
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
  text-align: center;
}

.simple-pagination li {
  display: inline-block;
  margin-right: 5px;
}

.simple-pagination li a,
.simple-pagination li span {
  color: #666;
  padding: 6px 12px;
  text-decoration: none;
  border: 1px solid #4d94ac;
  background-color: transparent;
  /*  box-shadow: 0px 0px 10px 0px #EEE;*/
}

.simple-pagination .current {
  color: #fff;
  background-color: #4d94ac;
  /*  border-color: #FF7182;*/
  border-radius: 4px;
}

.simple-pagination .prev.current,
.simple-pagination .next.current {
  background: transparent;
  border: 1px solid #959595;
  color: #959595;
}

.section3 .hiuser {
  padding: 40px;
  background: #3399cc !important;
  background: -webkit-linear-gradient(legacy-direction(-45deg),
      #29276b,
      #716aca) !important;
  background: linear-gradient(-45deg, #0077b2, #3399cc) !important;
  color: #fff;
  line-height: 3;
}

.section3 .hiuser h3,
.section3 .hiuser h3 span {
  color: #fff;
}

.section3 .hiuser p {
  color: #ffffffc7;
}

.section3 .flex-container {
  width: 100%;
  margin: auto;
  /*   margin: -45px auto 0;*/
}

.section3 .flex-container a[name="dang ky"] {
  background: #fff;
  border: none;
  border-radius: 5px;
  padding: 0.575rem 25px;
  color: #2993c7;
  margin-bottom: 30px;
  box-shadow: 1px 1px 5px #000000bf;
  text-decoration: none;
}





.confirm-modal .overplay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000bd;
}

.confirm-modal .choice-delete {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 50px;
  border-radius: 5px;
  z-index: 10;
}

.confirm-modal .choice-delete i {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  color: #39c;
}

.confirm-modal .choice-delete button {
  color: #fff;
  margin-top: 10px;
  background: #f10000;
  padding: 8px 18px;
  font-size: 16px;
}

.confirm-modal .choice-delete button:hover {
  box-shadow: 1px 1px 5px #000000a3;
}

.confirm-modal .choice-delete button:last-child {
  background: #39c;
}

.section3 .flex-container a[name="dang ky"]:hover {
  box-shadow: none;
}

.section3 .flex-container .member {
  text-align: center;
}

.section3 .flex-container .member table {
  width: 100% !important;
  padding: 30px 0;
}

.section3 .flex-container .member table .name-row {
  height: 40px;
  /*  background: #41b5ff;*/
  font-weight: 500;
  /*  color: #fff*/
}

.section3 .flex-container .member table .name-row th {
  padding-top: 8px;
  border: 1px solid #ababab;
}

.section3 .flex-container .member table tbody tr {
  background: #fff;
}

.section3 .flex-container .member table tbody tr:nth-child(even) {
  background: #fbfbfb;
}

.section3 .flex-container .member table tbody tr td {
  /*  padding: 5px 0;    */
  border: 1px solid #ababab;
}

.section3 .flex-container .member table tbody tr td textarea {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #39c;
  width: 145px;
}

/*.section3 .flex-container .member table tbody tr td button{
    -moz-box-shadow: 0px 10px 7px -7px #276873;
    -webkit-box-shadow: 0px 10px 7px -7px #276873;
    box-shadow: 0px 10px 7px -7px #276873;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #599bb3), color-stop(1, #408c99));
    background: -moz-linear-gradient(top, #41b5ff 5%, #1887be 100%);
    background: -webkit-linear-gradient(top, #41b5ff 5%, #1887be 100%);
    background: -o-linear-gradient(top, #41b5ff 5%, #1887be 100%);
    background: -ms-linear-gradient(top, #41b5ff 5%, #1887be 100%);
    background: linear-gradient(to bottom, #41b5ff 5%, #1887be 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#599bb3', endColorstr='#408c99',GradientType=0);
    background-color: #599bb3;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    cursor: pointer;
    color: #ffffff;
    font-weight: bold;
    padding: 7px 32px;
    border: none;
}*/
.section3 .flex-container .member table tbody tr td button {
  border: none;
  background: none;
  cursor: pointer;
}

.section3 .flex-container .member table tbody tr td button[name="save"] {
  display: none;
}

.results_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  float: left;
  margin-bottom: 0;
  padding-top: 25px;
  color: #434343;
}


.confirm-hidden-modal {
  display: none;
}

.confirm-visible-modal {
  display: block;
  /* background-color: 'red'; */
}

/**/

.drop_arrow {
  position: absolute;
  right: 16px;
  top: 6px;
}

.filter-dropdown select {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  background: none;
  border: 1px solid #878787;
  border-radius: 4px;
  padding: 0px;
  color: #878787;
  background-color: #eee;
  outline: none;
}

.filter_by_type_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  padding: 12px;
  margin-bottom: 0;
  color: #878787;
}

.view-member:focus {
  border: none;
  outline: none;
}

.edit-member:focus {
  border: none;
  outline: none;
}

.save-member:focus {
  border: none;
  outline: none;
}

.delete-member:focus {
  border: none;
  outline: none;
}

/*-------------------------------------------------------*/
.listing_number_box {
  border: 1px solid #eee;
  background: #fff;
  margin-bottom: 10px;
  padding: 30px;
  box-shadow: 0px 0px 10px 0px #eee;
  border-radius: 18px;
}

.listing_number {
  width: 100%;
}

.listing_number_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  padding: 6px 0px;
}

.listing_number_right_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  color: #878787;
  padding: 5px 35px;
}

.edit-save button {
  border: none;
  background: white;
  cursor: pointer;
}

.listing_number_title {
  font-family: "Gotham-Pro-Regular";
  font-size: 36px;
  color: #034a63;
  margin-top: 50px;
  padding: 0 45px;
  text-transform: uppercase;
}

.listing_form_dropdown option {
  padding: 10px;
}

/*----------------------------------------------------------sell listing edit------------------------------------*/

.list_id_form {
  background: #fff;
  margin-bottom: 10px;
  padding: 30px;
  /* box-shadow: 0px 0px 10px 0px #EEE; */
  border-radius: 18px;
  margin: 15px;
}

#wa_select {
  font-family: "Gotham-Pro-regular";
  font-size: 12px;
  border: 1px solid #878787;
  background: #fbfbfb;
  height: 42px;
  width: 100%;
  border-radius: 6px;
  padding: 0.375rem 0.75rem;
}

.select-dropdown {
  position: relative;
  /*  display: inline-block;*/
  max-width: 100%;
}

.select-dropdown__button {
  padding: 10px 35px 10px 15px;
  background-color: #fbfbfb;
  color: #616161;
  border: 1px solid #878787;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.select-dropdown__button::focus {
  outline: none;
}

.select-dropdown__button .zmdi-chevron-down {
  position: absolute;
  right: 10px;
  /*  top: 12px;*/
  font-size: 22px;
}

.select-dropdown__list {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  max-height: 300px;
  overflow: auto;
  margin: 0;
  padding: 0;
  list-style-type: none;
  opacity: 0;
  pointer-events: none;
  transform-origin: top left;
  transform: scale(1, 0);
  transition: all ease-in-out 0.3s;
  z-index: 2;
  border: 1px solid gray;
}

.select-dropdown__list.active {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1, 1);
}

.select-dropdown__list-item {
  display: block;
  list-style-type: none;
  padding: 10px 15px;
  background: #fff;
  border-top: 1px solid #e6e6e6;
  font-size: 14px;
  line-height: 1.4;
  cursor: pointer;
  color: #616161;
  transition: all ease-in-out 0.3s;
}

.arrow_down {
  position: absolute;
  right: 12px;
  top: 15px;
}

.listing_form_dropdown {
  font-family: "Gotham-Pro-Regular";
  background-color: #fbfbfb !important;
  /*  margin: 50px;*/
  width: 100%;
  font-size: 12px !important;
  padding: 10px 35px 10px 10px;
  /*  font-size: 16px;*/
  border: 1px solid #878787;
  border-radius: 6px;
  height: 42px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("../img/sell_listing_edit/Icon ionic-ios-arrow-down.png");
  background-repeat: no-repeat;
  /*  background-size: 4%;*/
  background-position: 98% 50%;
}

/* CAUTION: IE hackery ahead */
.listing_form_dropdown::-ms-expand {
  display: none;
  /* remove default arrow on ie10 and ie11 */
}

.update_listing_btn {
  font-family: "Gotham-Pro-regular";
  font-size: 14px;
  background: #4d94ac;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 14px 15px;
  text-transform: uppercase;
  cursor: pointer;
}

.view_listing_text {
  font-family: "Gotham-Pro-regular";
  font-size: 28px;
  text-transform: uppercase;
  padding: 20px 0;
}

.listing_object_right_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  color: #878787;
  padding: 5px 5px;
}

/**/

.upload_images_section {
  width: 666px;
  margin: 0 auto;
  padding: 30px;
  background: #ffffff;
  border-radius: 18px;
}

.save_btn {
  font-family: "Gotham-Pro-regular";
  font-size: 16px;
  color: #ffffff;
  background: #4d94ac;
  border: none;
  border-radius: 4px;
  padding: 10px;
  min-width: 176px;
  margin-bottom: 50px;
  cursor: pointer;
}

.ft-in_select {
  width: 100px;
}

.section_2 {
  position: relative;
  top: 90px;
  background-color: #f9f9f9;
}

/**/

.item_content .overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(106, 123, 231, 0.8);
  opacity: 0;
}

.listing-img-container {
  padding: 5px;
  text-align: left;
}

.listing-item-content {
  box-shadow: none;
}

#map {
  height: 100%;
  width: 100%;
  margin: 0;
  /*  z-index: 990; */
  border-radius: 16px;
}

#map_2 {
  height: 100%;
  width: 100%;
  margin: 0;
  /*  z-index: 990; */
  border-radius: 16px;
}

#map-container {
  position: relative;
  top: 0;
  left: 0;
  height: 597px;
  width: 100%;
  z-index: 0;
  margin-bottom: 60px;
}

.gmnoprint {
  margin: 10px;
}

#map-container.fullwidth-home-map {
  height: 600px;
  margin-bottom: 0;
}

#map-container.fullwidth-home-map.hp3 {
  height: 490px;
}

#map-container.fullwidth-home-map .main-search-inner {
  position: absolute;
  display: block;
  bottom: 60px;
  top: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  padding-bottom: 0;
  z-index: 9999;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

#map-container.fullwidth-home-map .main-search-input {
  margin-top: 0;
}

.face.front {
  border-color: #707070 !important;
  background: #707070;
}

.face.back {
  background: #707070 !important;
}

#map-container.fullwidth-home-map_2 {
  height: 900px !important;
  margin-bottom: 0;
}

/* ==================== half-map-sec ================ */

.half-map-sec {
  padding-bottom: 80px;
}

.header.shd {
  -webkit-box-shadow: 0 0 10px rgba(1, 1, 2, 0.08);
  -moz-box-shadow: 0 0 10px rgba(1, 1, 2, 0.08);
  -ms-box-shadow: 0 0 10px rgba(1, 1, 2, 0.08);
  -o-box-shadow: 0 0 10px rgba(1, 1, 2, 0.08);
  box-shadow: 0 0 10px rgba(1, 1, 2, 0.08);
  position: relative;
}

.half_map header .container {
  padding: 0 35px;
  width: 100%;
  max-width: 100%;
}

.half-map-sec .list-products .card .img-block {
  width: 33.2%;
}

.half-map-sec .list-products .card_bod_full {
  width: 66.8%;
}

.half-map-sec>.container>.row>.col-xl-6 {
  padding: 0;
}

.half-map-sec .listing-directs {
  padding: 0 50px 0 35px;
}

.half-map-sec .list_products .row {
  margin: 0;
}

.half-map-sec .list_products>.row>.col-lg-6:nth-child(odd) {
  padding-left: 0;
}

.half-map-sec .list_products>.row>.col-lg-6:nth-child(even) {
  padding-right: 0;
}

.half-map-sec .widget-property-search {
  float: left;
  width: 100%;
  padding: 34px 50px 0 35px;
}

.half-map-sec .widget-property-search .form_field {
  margin-bottom: 19px;
}

.half-map-sec .form_field.full {
  width: 100%;
  padding: 0;
}

.half-map-sec .banner-search .form_field {
  padding-right: 0;
}

.half-map-sec .feat-srch .form_field {
  float: right;
}

.half-map-sec .banner-search .form_field:nth-child(2) {
  padding-left: 0;
}

.half-map-sec .form_field {
  width: 25%;
  padding: 0 9px;
}

.half-map-sec .widget-property-search .banner-search {
  padding: 0;
}

.feat-srch {
  float: left;
  width: 100%;
  margin-bottom: 7px;
  margin-top: -7px;
}

.feat-srch .form_field {
  padding-right: 4px;
}

.more-feat {
  float: left;
  width: 25%;
  margin-top: 20px;
  padding-left: 0;
}

.more-feat h3 {
  color: #242526;
  font-size: 12px;
  cursor: pointer;
}

.more-feat i {
  font-size: 18px;
  padding-right: 10px;
  position: relative;
  top: 1px;
}

.feat-srch>.form_field {
  width: 73.9%;
}

.feat-srch>.form_field .btn {
  border-radius: 0;
}

.half-map-sec #map-container.fullwidth-home-map {
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  z-index: 50;
  overflow: hidden;
  border: 0px;
  border-radius: 15px;
}

#map-container div#circle-example {
  border-radius: 15px;
}

/*
header.fix {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #fff;
}*/

.sortby>span {
  color: #67686c;
  font-size: 14px;
  font-weight: 500;
  float: left;
}

.sortby .drop-menu {
  float: left;
  border: 0;
  margin: 0;
  padding: 0;
  width: 93px;
  padding-left: 6px;
}

.sortby .drop-menu .select i {
  right: 0;
  margin-top: 4px;
}

.sortby .drop-menu .dropeddown {
  padding: 0 10px;
  width: 130px;
}

.sortby .drop-menu .select {
  padding: 0;
  font-size: 14px;
  color: #242526;
  font-weight: 500;
}

.drop-menu .select .selected {
  color: #878787;
}

.drop-menu {
  background: #fff;
  width: 100%;
  display: inline-block;
  position: relative;
  font-size: 14px;
  height: 100%;
  text-align: left;
  margin-top: 25px;
  /*  margin: 15px 0;*/
  /*  border-left: 1px solid #e0e1e6;*/
  /*padding-left: 10px; */
  /*  padding: 5px 10px 5px 10px;*/
}

.drop-menu .select {
  font-family: "Gotham-Pro-Medium";
  cursor: pointer;
  display: block;
  padding: 9px 20px;
  color: #7e7f82;
  font-weight: 500;
  padding-left: 12px;
  font-size: 12px;
  text-transform: capitalize;
  /*    height: 37px;*/
  min-width: 125px;
}

.drop-menu .select i {
  font-size: 14px;
  /*      margin-top: 5px;*/
  cursor: pointer;
  float: right;
  position: absolute;
  right: 6px;
}

.drop-menu .select .currency {
  font-size: 13px;
  cursor: pointer;
  float: right;
}

.drop-menu.active {
  border-radius: 5px 5px 0 0;
}

.drop-menu.active:hover,
.drop-menu.active:focus {
  border-radius: 5px 5px 0 0;
}

.drop-menu.active .select {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none;
}

.drop-menu.active .select i {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.drop-menu:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.drop-menu .dropeddown {
  position: absolute;
  width: 100%;
  left: 0;
  margin-top: 1px;
  overflow: hidden;
  display: none;
  /*max-height: 144px;
    overflow-y: auto;*/
  z-index: 999;
  background: #fff;
  padding: 0;
  list-style: none;
  padding: 0 10px;
  border: 1px solid #878787;
  top: 112%;
  border-radius: 9px;
}

.drop-menu .dropeddown li {
  font-family: "Gotham-Pro-regular";
  padding: 5px 0 4px 0;
  cursor: pointer;
  color: #242526;
  font-size: 12px;
  /*      border-bottom: 1px solid #e0dfe5;*/
}

.drop-menu.active .select>span,
.drop-menu.active .select i,
.drop-menu .dropeddown li:hover {
  color: #7686e6;
}

.drop-menu .dropeddown li:last-child {
  border-bottom: 0;
}

/*------------------------------------------------------------------------------*/
.for_buyer_btn {
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  font-weight: 500;
  background: #034a63;
  height: 40px;
  width: 166px;
  border: 1px solid #ffffff;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
}

.for_sellers_btn {
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  font-weight: 500;
  background: #ffffff;
  height: 40px;
  width: 166px;
  border: 1px solid #034a63;
  color: #034a63;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
}

.buyer_package_text_2 {
  font-family: "Gotham-Pro-Bold";
  font-size: 15px;
  color: #29a6d4;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  text-transform: uppercase;
}

/*watermark*/
.watermark {
  height: 100px;
}

.watermark::after {
  content: "";
  background: url("../img/Repeat Grid 35.png");
  opacity: 0.1;
  top: 70px;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 0;
  background-position: 0 0;
  background-repeat: repeat;
  margin: 20px;
}

.watermark_2::after {
  content: "";
  background: url("../img/repeat_grid_2.png");
  opacity: 0.2;
  top: 70px;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 0;
  background-position: 0 0;
  background-repeat: repeat;
  margin: 20px;
}

.free_membership_ends_in_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  color: #878787;
  padding: 8px;
  margin-bottom: 0;
}

.free_membership_time {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  color: #ef1010;
  background: #fff2f2;
  border: 1px solid #ef1010;
  padding: 12px;
  border-radius: 4px;
  margin-right: 10px;
  height: 44px;
}

.recommended_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 12px;
  color: #ffffff;
  background: #034a63;
  padding: 5px 20px;
  border-radius: 6px 6px 0 0;
  width: 130px;
  position: absolute;
  top: -27px;
  left: 86px;
}

.seller_package_section {
  padding: 20px;
  background: #ffffff;
}

.potential_buyer_title {
  font-family: "Gotham-Pro-Regular";
  font-size: 18px;
  color: #034a63;
}

.potential_buyer_para {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  color: #878787;
  text-align: justify;
}

.seller_package_video embed {
  background: #4d94ac;
  border-radius: 10px;
}

.seller_package_footer {
  height: 15px;
  background: #4d94ac;
}

.search_inventory_btn {
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  font-weight: 500;
  background: #034a63;
  padding: 0 12px;
  height: 40px;
  /*  width: 166px;*/
  border: 1px solid #ffffff;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  /*margin-left: 40px;*/
}

.subscription_required_text {
  font-family: "Gotham-Pro-Bold";
  font-size: 32px;
  text-align: center;
  color: #034a63;
  padding-top: 50px;
}

.your_two_hour_membership_para {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  color: #434343;
  padding: 0 60px;
  text-align: center;
}

.buy_membership_btn {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  color: #ffffff;
  background: #4d94ac;
  padding: 10px 45px;
  border: none;
  border-radius: 4px;
  margin-bottom: 50px;
}

.hide {
  display: none;
}

.popup-content_2 {
  text-align: center;
  padding: 183px;
}

.need_to_buy_tokens_para {
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  color: #878787;
}

/*------------------------------------search inventory how it works---------------------------------*/

.filter_your_search_inventory_title {
  font-family: "Gotham-Pro-Regular";
  font-size: 30px;
  color: #034a63;
  text-align: center;
  margin: 40px 0 20px 0;
}

.advanced_filters_link {
  font-family: "Gotham-Pro-Regular";
  font-size: 12px;
  color: #29a6d4;
  text-decoration: underline;
}

.total_vessels_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  text-align: center;
}

.buy_token_box {
  background-color: #fff;
  border-radius: 18px;
  border: 1px solid #4d94ac;
  overflow: hidden;
  margin-bottom: 50px;
  height: 305px;
}

.buy_token_title {
  height: 60px;
  background: #034a63;
  padding: 16px;
}

.buy_token_title_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 0;
}

.buy_token_layout {
  text-align: center;
  padding: 25px;
}

.buy_token_currency {
  display: inline-flex;
}

.buy_token_currency .token_currency {
  padding-right: 4px;
  font-size: 16px;
  font-weight: 500;
  color: #034a63;
  bottom: -16px;
  position: relative;
}

.buy_token_layout .buy_token_currency .value {
  font-family: "Gotham-Pro-Regular";
  font-size: 30px;
  color: #034a63;
}

.buy_token_box .divider {
  display: block;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0px 32px;
}

.hst_text_2 {
  font-family: "Gotham-Pro-Regular";
  font-size: 12px;
  color: #878787;
  text-align: center;
}

.buy_now_btn {
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  color: #ffffff;
  background: #034a63;
  border: none;
  border-radius: 4px;
  padding: 10px 60px;
  cursor: pointer;
}

.buy_token_footer {
  padding: 20px;
  text-align: center;
}

.custom_box {
  background-color: #fff;
  border-radius: 18px;
  border: 1px solid #434343;
  overflow: hidden;
  margin-bottom: 50px;
}

.custom_title {
  height: 60px;
  padding: 16px;
  background: #434343;
}

.custom_layout {
  padding: 10px;
}

.enter_okeness_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 10px;
}

.enter_okeness_input {
  font-family: "Gotham-Pro-Regular";
  border: 1px solid #878787;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}

.for_each_token_value_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 8px;
  padding-top: 5px;
  margin-bottom: 0;
}

.custom_price_flex {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.custom_price_flex h3 {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  margin-bottom: 0;
}

.custom_box .divider {
  display: block;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0px 10px;
}

.advanced_filters_title {
  font-family: "Gotham-Pro-Regular";
  font-size: 32px;
  color: #034a63;
  padding: 20px 0;
}

.filter_box {
  background: #ffffff;
  /*  display: flex;*/
  padding: 16px;
  border-radius: 10px;
  margin: 10px 0;
}

.filter_box_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  color: #878787;
  padding-bottom: 15px;
}

.pure-material-radio {
  z-index: 0;
  position: relative;
  display: inline-block;
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
  font-family: var(--pure-material-font,
      "Roboto",
      "Segoe UI",
      BlinkMacSystemFont,
      system-ui,
      -apple-system);
  font-size: 16px;
  line-height: 1.5;
  margin-left: 25px;
}

/* Input */
.pure-material-radio>input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: -1;
  position: absolute;
  left: -10px;
  top: -8px;
  display: block;
  margin: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  outline: none;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity 0.3s, transform 0.2s;
}

/* Span */
.pure-material-radio>span {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  color: #878787;
  display: inline-block;
  width: 100%;
  cursor: pointer;
}

/* Circle */
.pure-material-radio>span::before {
  content: "";
  display: inline-block;
  box-sizing: border-box;
  margin: 2px 10px 2px 0;
  border: solid 2px;
  /* Safari */
  border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  vertical-align: top;
  transition: border-color 0.2s;
}

/* Check */
.pure-material-radio>span::after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: -2px;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
  transform: translate(5px, 5px) scale(0);
  transition: transform 0.2s;
}

/* Checked */
.pure-material-radio>input:checked {
  background-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
}

.pure-material-radio>input:checked+span::before {
  border-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
}

.pure-material-radio>input:checked+span::after {
  transform: translate(5px, 5px) scale(1);
}

/* Hover, Focus */
.pure-material-radio:hover>input {
  opacity: 0.04;
}

.pure-material-radio>input:focus {
  opacity: 0.12;
}

.pure-material-radio:hover>input:focus {
  opacity: 0.16;
}

/* Active */
.pure-material-radio>input:active {
  opacity: 1;
  transform: scale(0);
  transition: transform 0s, opacity 0s;
}

.pure-material-radio>input:active+span::before {
  border-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
}

/* Disabled */
.pure-material-radio>input:disabled {
  opacity: 0;
}

.pure-material-radio>input:disabled+span {
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
  cursor: initial;
}

.pure-material-radio>input:disabled+span::before {
  border-color: currentColor;
}

.pure-material-radio>input:disabled+span::after {
  background-color: currentColor;
}

.reset_btn {
  font-family: "Gotham-Pro-Regular";
  font-size: 12px;
  color: #878787;
  border: 1px solid #878787;
  padding: 10px 20px;
  border-radius: 8px;
  text-transform: uppercase;
  background: #ffffff;
  cursor: pointer;
}

.apply_btn {
  font-family: "Gotham-Pro-Regular";
  font-size: 12px;
  color: #ffffff;
  background: #4d94ac;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  text-transform: uppercase;
  cursor: pointer;
}

.product_time {
  font-family: "Gotham-Pro-Regular";
  font-size: 12px;
  color: #ef1010;
  background: #fff2f2;
  border: 1px solid #ef1010;
  padding: 6px 15px;
  width: max-content;
  border-radius: 4px;
  float: right;
}

.product_left_content {
  float: left;
}

.contact_us_btn_2 {
  font-family: "Gotham-Pro-Regular";
  font-size: 12px;
  background: #3d670a;
  color: #ffffff;
  border: none;
  border-radius: 2px;
  padding: 8px 15px;
  text-transform: uppercase;
  cursor: pointer;
}

.send_message_btn {
  font-family: "Gotham-Pro-Regular";
  font-size: 12px;
  background: #29a6d4;
  color: #ffffff;
  border: none;
  border-radius: 2px;
  padding: 8px 15px;
  text-transform: uppercase;
  cursor: pointer;
}

.buy_btn_2 {
  font-family: "Gotham-Pro-Regular";
  font-size: 12px;
  color: #ffffff;
  background: #4d94ac;
  border: none;
  padding: 10px 30px;
  border-radius: 4px;
  text-transform: uppercase;
  cursor: pointer;
}

.sell_token_box {
  background: white;
  height: unset;
  padding: 30px;
  margin-top: 40px;
  border-radius: 18px;
  display: flex;
  justify-content: space-between;
}

.sale_by_owner {
  font-family: "Gotham-Pro-Regular";
  font-size: 8px;
  padding: 5px;
  color: #ffffff;
  background: #29a6d4;
  border-radius: 4px;
  width: max-content;
  position: absolute;
  top: 16px;
  left: 30px;
}

.upgrade_to_membership_text {
  font-family: "Gotham-Pro-Regular";
  font-size: 14px;
  color: #878787;
  padding-top: 10px;
}

.choose_package_box {
  text-align: center;
  padding: 40px 20px;
}

.search_inventory_video {
  border-radius: 18px;
}

/*.tab_btn{
  font-family: "Gotham-Pro-Regular";
  font-size: 16px;
  font-weight: 500;
  background: #ffffff;
  height: 40px;
  width: 166px;
  border:1px solid #034A63;
  color: #034A63;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  padding: 10px 20px;
}

.tab_btn.active{
    background-color: #034A63;
    color: #ffffff;
}

.tab-content.active {
  display:block;
}

.tab-content[class*="content-"] {
  display:none;
}*/

.popup-trigger {
  /*display: block;
  margin: 0 auto;
  padding: 35px 55px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 32px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  transition: box-shadow 0.3s ease 0s;*/
}

.popup-trigger:hover {
  /*  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);*/
}

.popup_map_box {
  position: fixed;
  inset: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  padding: 30px 15px;
  color: #000;
  visibility: hidden;
  transition: visibility 0.8s ease 0s;
}

.popup_map_box::before {
  content: "";
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  opacity: 0;
  transition: opacity 0.8s ease 0s;
}

.popup__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  width: 100%;
  /*  min-height: 100%;*/
}

.popup__body {
  width: 100%;
  /*  max-width: 800px;*/
  /*  padding: 50px;*/
  background-color: #fff;
  border-radius: 16px;
  transform: scale(0);
  transition: all 0.3s ease 0s;
}

.popup__close {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 999;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.blur-position {
  position: relative;
}

.lock {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
}

.popup__close::after,
.popup__close::before {
  content: "";
  position: absolute;
  right: 0;
  width: 100%;
  height: 0.125rem;
  background-color: #000;
  transition: all 0.3s ease 0s;
}

.popup__close::after {
  bottom: calc(50% - 0.0625rem);
  transform: rotate(45deg);
}

.popup__close::before {
  top: calc(50% - 0.0625rem);
  transform: rotate(-45deg);
}

.popup__text {
  /*  font-size: 26px;*/
  /*  font-weight: 600;*/
  /*  text-align: center;*/
}

.popup_map_box.show {
  visibility: visible;
  overflow: auto;
}

.popup_map_box.show::before {
  opacity: 1;
}

.popup_map_box.show .popup__body {
  transform: scale(1);
  transition: all 0.3s ease 0.2s;
}

/*-------------------------------------------------------------*/

/*  */
.advanced-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5px;
}

.arrow-right-map-popup {
  background-color: white;
  padding: 3px 7px;
  border-radius: 2px;
  cursor: pointer;
}

.goog-te-gadget-icon {
  display: none;
}


.goog-te-gadget-simple {
  background-color: #ecebf0 !important;
  border: 0 !important;
  font-size: 10pt;
  font-weight: 800;
  display: inline-block;
  padding: 10px 10px !important;
  cursor: pointer;
  zoom: 1;
}

.goog-te-gadget-simple span {
  color: #3e3065 !important;

}


/* New Code */

.sortingTag {
  float: right;
  background-color: red;
  color: white;
  font-weight: 500;
  padding: 2px 10px;
  border-radius: 5px;
  font-size: small;

}


/* My css S */

.ct_navbar_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  flex-wrap: wrap;
}

div#nav-hamburger {
  display: flex;
  align-items: center;
  gap: 15px;
}


.ct_btn_width_242 {
  width: 100% !important;
  max-width: 241px;
}

.css-b62m3t-container {
  padding-block: 6px;
  margin-block: 0px;
}

main.sc-gsTEea.Icbof input.pac-target-input {
  padding-block: 6px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  width: 100% !important;
  border-radius: 5px;
  margin-top: 6px;
}


.react-tel-input .form-control {
  width: 100% !important;
  height: 44px;
}

#map-canvas input.pac-target-input {
  height: 31px;
  text-indent: 30px;
  position: relative;
  border-color: #d8d8d8;
  outline: none;
  border-width: 0px;
  position: relative;
  top: -4px;
}

.location_icon {
  position: absolute;
  left: 23px;
  top: 22px;
  z-index: 6;
}

.react-tel-input .selected-flag .arrow {
  margin-top: 0px !important;
}

.react-tel-input .selected-flag .flag {
  top: 34% !important;
  margin-top: 0px !important;
}


.react-tel-input .flag {
  width: 16px !important;
  height: 11px !important;
  object-fit: contain !important;
  background-repeat: no-repeat;
}

.ct_edit_td_action img {
  width: 20px;
}

button.Toastify__close-button.Toastify__close-button--light svg {
  width: 25px;
}

.footer_bottom nav.bg-light {
  background-color: transparent !important;
}

.footer_bottom nav.bg-light a.nav-link {
  color: #000 !important;
}


div#\:0\.targetLanguage {
  background-color: #004964 !important;
  border: 1px solid #fff !important;
  border-radius: 10px;
}

.goog-te-gadget-simple span {
  color: #fff !important;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
}

.translated-ltr body {
  top: 0px !important;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none;
}

.msger-chat .msg-note {
  display: flex;
  align-items: center;
  gap: 3px;
  margin-left: 10px;
  flex-wrap: wrap;
}

.msger-chat .msg-note p {
  margin-bottom: 0px;
  font-size: 18px;
}

.msger-chat .msg-note span {
  font-weight: 600;
  font-size: 18px;
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 50%;
  bottom: 0;
  padding: 0;
  background-color: #f5f5f5;
  border: 1px solid #cacaca;
  border-radius: 3px 0 0 3px;
  height: 44px;
  transform: translateY(-50%);
  left: 0px;
  width: 50px;
}

.react-tel-input .selected-flag .arrow {
  position: relative;
  top: 50%;
  margin-top: -2px;
  left: 16px;
  width: 0;
  height: 0;
  border-left: 2px solid transparent;
  border-right: 2px solid #aaa;
  border-top: 2px solid transparent;
  background-size: contain;
  border-bottom: 2px solid #aaa;
  border-radius: 0px;
  display: inline-block !important;
  padding: 2px;
  transform: rotate(45deg);
}

.react-tel-input .selected-flag {
  padding-left: 0px;
  margin-left: -8px;
}

.react-tel-input .country-list {
  flex-direction: column;
  align-items: self-start;
  gap: 0px;
}

.multi_step_form select#inlineFormCustomSelect {
  margin-bottom: 0px !important;
  margin-left: 0px !important;
}

.ct_fs_20 {
  font-size: 20px !important;
  color: #000 !important;
  margin-bottom: 20px !important;
  font-family: "Gotham-Pro-regular";
  font-weight: 700;
}

svg.MuiSvgIcon-root {
  width: 34px !important;
  height: 34px !important;
}

.ct_suggestion_btn_1 {
  width: fit-content !important;
}

.ct_payment_cards {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-block: 30px;
}

.ct_payment_cards img {
  width: 70px;
  margin: auto;
  display: block;
}

button.Contact_us_btn.equiry_btn.mt-2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct_Scroll_text_mob {
  display: none;
}

.ct_footer_white .footer_bottom nav.bg-light a.nav-link {
  color: #fff !important;
}

.ct_payment_form_bg {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  border-top: 4px solid #034a63;
  margin-top: 50px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.09);
}

.ct_payment_form_bg .StripeElement.StripeElement--empty {
  border: 1px solid #d8d8d8;
  background: whitesmoke;
}

.ct_payment_form_bg input#name {
  height: 43.19px;
  padding-inline: 15px;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  background: whitesmoke;
  outline: none;
}

.ct_payment_form_bg label {
  font-weight: 600;
}

.ct_payment_form_bg .sub_total {

  margin-bottom: 8px;
}


.ct_short_option_map_on_mobile {
  display: none;
}

.ct_mt_94_dash {
  margin-top: 94px !important;
}




/* for ribbon on membership page */
.cover-ribbon {
  height: 115px;
  width: 115px;
  position: absolute;
  right: 12px;
  top: -6px;
  overflow: hidden;
}

.cover-ribbon .cover-ribbon-inside {
  background: #EA4335;
  color: #FFF;
  transform: rotate(45deg);
  position: absolute;
  right: -35px;
  top: 15px;
  padding: 10px;
  min-width: 127px;
  text-align: center;
}

.cover-ribbon .cover-ribbon-inside:before {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 10px solid #992C23;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  top: 35px;
  transform: rotate(-45deg);
}

.cover-ribbon .cover-ribbon-inside:after {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-left: 10px solid #992C23;
  border-bottom: 7px solid transparent;
  position: absolute;
  left: 95%;
  right: 0;
  top: 34px;
  bottom: 0;
  content: "";
  transform: rotate(-45deg);
}



/* =====================seller listing video modal start =================== */
.SellerListingVideoModal {
  position: absolute;
  top: 45px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000045;
  transition: opacity 300ms;
  visibility: visible;
  opacity: 1;
  /*height: 114vh;*/
}

.SellerListingVideoModal:target {
  visibility: visible;
  opacity: 1;
}

.video_popup_modal {
  position: relative;
  margin: 100px auto;
  padding: 30px;
  background: white;
  box-sizing: border-box;
  /*box-shadow: 10px 10px 10px 10px #eee;*/
  width: 500px;
  border-radius: 20px;
  border: 10px solid #004964;
  z-index: 20;
}

.video_popup_modal .videoclose {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 17px;
  font-weight: 700;
  color: #043e56;
  background: #ffffff;
  height: 28px;
  width: 28px;
  text-align: center;
  border: 1px solid #043e56;
  border-radius: 50%;
}

.seller_video_text {
  font-family: "Gotham-Pro-Bold";
  font-weight: 600;
  font-size: 26px;
  text-align: center;
  color: #043e56;
}


/* =============================seller listing video modal end =============================*/